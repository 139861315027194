import { AnyAction } from 'redux';

import { IWish } from '../_reducers/interfaces'

import { 
    DELETE_WISH,
    REQUEST_ORG_WISHES,
    RECEIVE_ORG_WISHES,
    REQUEST_WISH,
    RECEIVE_WISH,
} from './constants'

import { AxiosError } from 'axios';


import { client } from '../Client'

export const deleteWish = (wishId:string)=>({type:DELETE_WISH, wishId})

export const requestOrgWishes = () => ({type:REQUEST_ORG_WISHES})

export const receiveOrgWishes = (wishes:IWish[]) => ({
    type:RECEIVE_ORG_WISHES,
    wishes,
})

export const requestWish = (wishId: string) => ({
    type:REQUEST_WISH,
    wishId,
})

export const receiveWish = (wish:IWish) => ({
    type:RECEIVE_WISH,
    wish,
})

export const getOrgWishes = (idToken:string, orgId:string) => {
    return((dispatch:any, getState:any):Promise<AnyAction>=>{
        dispatch(requestOrgWishes());
        return new Promise((resolve,reject)=>{
            client.getOrgWishes(idToken, orgId)
            .then((wishes:IWish[])=>{
                dispatch(receiveOrgWishes(wishes))
            }).catch((err:AxiosError)=>{
                console.log(err)
            })
        })
    })
}

export const getWish = (idToken:string, wishId:string) => {
    return((dispatch:any, getState:any):Promise<AnyAction>=>{
        dispatch(requestWish(wishId));
        return new Promise((resolve,reject)=>{
            client.getWish(idToken, wishId)
            .then((wish:IWish)=>{
                console.log('made it to the get wish promise completion in thunk action')
                dispatch(receiveWish(wish))
            }).catch((err:AxiosError)=>{
                console.log(err)
            })
        })
    })
}
import {
    RECEIVE_ORGANIZATIONS,
    REQUEST_ORGANIZATIONS,
} from '../_actions'

// define default state
import { IOrgReducer } from './interfaces'



const defaultState: IOrgReducer = {
    isFetching: false,
    organizations: [],
    lastUpdated: 0
}

// reducer
export const organizations = (
    state=defaultState,
    action:any,
): IOrgReducer => {
    switch (action.type) {
        case RECEIVE_ORGANIZATIONS:
            return Object.assign({},state,{
                isFetching:false,
                organizations: action.organizations,
                lastUpdated: Date.now(),
            })
        
        case REQUEST_ORGANIZATIONS:
            return Object.assign({},state,{
                ...state,
                isFetching:true,
            })

        default:
            return state
    }
}
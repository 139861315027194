import { FC, useEffect, useState } from 'react';
import { useAuth0} from '@auth0/auth0-react'
import { AppHeader, Button,  Nav, TextInput } from '../components'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { IInvite, IOrgReducer, IUser } from '../services/_reducers/interfaces';

import validator from 'validator'

import { client } from '../services/Client'
import { getOrganizations } from '../services/_actions';

interface IRootSelector {
    user: IUser,
    organizations: IOrgReducer
}

interface IParams {
    groupId: string,
}


export const InviteScene:FC = () => {
    const { isAuthenticated } = useAuth0();
    
    const history = useHistory()
    const dispatch = useDispatch()
    const { user, organizations } = useSelector((state:IRootSelector) => state)
    const [validationMsg, setValidationMessage] = useState("")
    const [disableSend, setDisableSend] = useState(true)
    const [email, setEmail] = useState("")
    let { groupId } = useParams<IParams>()
    
    const org = organizations.organizations.find(org=>org.id === groupId)!
    
    
    const handleSend = () => {
        client.createInvite(
            user.idToken!,
            {
                email,
                orgId: org.id
            }
            ).then((invite:IInvite)=>{
                console.log(invite)
                dispatch(getOrganizations(user?.idToken))
                history.push(`/group/${org.id}`)
            }).catch((err)=>{
                console.log(err)
                history.push(`/group/${org.id}`)
            })
        }
        
        
        
        useEffect(()=>{
            if(!validator.isEmail(email)){
                setDisableSend(true)
                setValidationMessage("Not a valid email.")
            } else {
                setDisableSend(false)
                setValidationMessage("")
            }
        },[email])
        
        if(!isAuthenticated){
            return(<Redirect to="/"/>)
        }
        
        return(
            <>
        <Nav/>
        <div className="py-10 h-screen">
    <AppHeader>Send an Invitation to {org.name}</AppHeader>
            <div className="max-w-7xl mx-auto">
                <div className="space-y-8 p-8">
                    <div className="space-y-1 ">
                        <TextInput
                            label="Email"
                            name="email"
                            id="email"
                            value={email}
                            setValue={setEmail}
                        />
                        <div className="flex justify-end">
                            <p className="mt-1 max-w-2xl text-sm text-red-500 p-4">
                                {validationMsg}
                            </p>
                        </div>
                        <div className="flex justify-end">
                            <Button
                                disabled={disableSend}
                                onClick={handleSend}
                            >Send</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
import { FC, useEffect, useState } from 'react';
import { useAuth0} from '@auth0/auth0-react'
import { AppHeader, Button, Nav, TextArea, TextInput } from '../components'
import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { IOrganization, IUser } from '../services/_reducers/interfaces';
import { getOrganizations } from '../services/_actions';

import { client } from '../services/Client'


export const CreateGroupScene:FC = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const { user } = useSelector((state:{user:IUser})=>state)
    const { isAuthenticated } = useAuth0();
    const [groupName, setGroupName] = useState("")
    const [groupDescription, setGroupDescription] = useState("")
    const [validationMsg, setValidationMessage] = useState("")
    const [disableCreate, setDisableCreate] = useState(true)

    const handleCreate = () => {
        console.log(JSON.stringify(groupName))
        client.createOrg(user.idToken,{
            name: groupName,
            description: groupDescription,
        }).then((org:IOrganization)=>{
            console.log(org)
            dispatch(getOrganizations(user.idToken))
            history.push('/groups')
        }).catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        if(groupName==="" || groupDescription===""){
            setDisableCreate(true)
            setValidationMessage("Both Group Name and Group Description are required.")
        } else {
            setDisableCreate(false)
            setValidationMessage("")
        }
    },[groupName, groupDescription])

    if(!isAuthenticated){
        return(<Redirect to="/"/>)
    }

    return(
        <>
        <Nav/>
        <div className="py-10 h-screen">
            <AppHeader>Create a Group!</AppHeader>
            <div className="max-w-7xl mx-auto">
                <div className="space-y-8 p-8">
                    <div className="space-y-1 sm:max-w-xs ">
                        <TextInput
                            label="Group Name"
                            name="group_name"
                            id="group_name"
                            value={groupName}
                            setValue={setGroupName}
                        />
                        <TextArea
                            label="Group Description"
                            name="group_description"
                            id="group_description"
                            value={groupDescription}
                            setValue={setGroupDescription}
                        />
                        <div className="flex justify-end">
                            <p className="mt-1 max-w-2xl text-sm text-red-500 p-4">
                                {validationMsg}
                            </p>
                        </div>
                        <div className="flex justify-end">
                            <Button
                                disabled={disableCreate}
                                onClick={handleCreate}
                            >Create</Button>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
import { FC } from 'react';
import { IMembership, IUserReducer } from '../../../services/_reducers/interfaces';
import { client } from '../../../services/Client'
import { useSelector } from 'react-redux'

interface IProps {
    membership: IMembership,
    getMemberships: (groupId:string)=>void,
}

interface IState {
    user: IUserReducer
}

export const RemoveMemberButton:FC<IProps> = (props:IProps) => {
    const { user } = useSelector((state:IState)=>state)
    const { membership } = props

    const removeMembership = () =>{
        client.destroyMembership(user.idToken!, membership.id)
        .then((message:string)=>{
            console.log(message)
            props.getMemberships(membership.organization.id)
        })
        .catch((err:any)=>{console.log(err)})
    }

    return(            
        <button className="
            flex align-center justify-center
            p-4  border border-gray-200 rounded-r-md
            bg-white hover:bg-red-200
            text-gray-900 hover:text-gray-900
            cursor-not-allowed
            "
            title="Remove User"
            onClick={removeMembership}
            disabled={true}
            >
            <svg className="h-5 w-5 mx-auto my-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </button>
    )
}

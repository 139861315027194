
import { FC } from 'react';
import cx from 'classnames'
export interface IOption {id:string, name: string, unavailable?:boolean}

interface IProps {
  option: IOption,
  selected: boolean;
  clickSelected: (option:IOption)=>void;
  highlight:boolean;
}

export const SelectOption:FC<IProps> = (props:IProps) => {

    const { clickSelected, highlight, option, selected } = props

    const nameClasses = cx({
        'font-normal': !selected,
        'font-semibold': selected,
        'block': true,
        'truncate': true,
    })

    const highlightClasses = cx({
      'bg-green-800': highlight,
      'text-white': highlight,
      'text-gray-900': !highlight,
    })


    return(
      <li 
        id="listbox-item-0"
        className={`${highlightClasses} cursor-default select-none relative py-2 pl-8 pr-4 hover:text-white hover:bg-green-800`}
        onClick={()=>{clickSelected(option)}}
        >
          <span className={`${nameClasses}`}>{option.name}</span>
      {selected?
        <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 hover:text-white hover:bg-green-600">
            {/* <!-- Heroicon name: check --> */}
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" >
            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
        </span>:null
      }
    </li>

  )
}

import { AnyAction } from 'redux';

import { IOrganization } from '../_reducers/interfaces'

import { 
    RECEIVE_ORGANIZATIONS,
    REQUEST_ORGANIZATIONS
} from './constants'
import { AxiosError } from 'axios';


import { client } from '../Client'

export const requestOrganizations = () => ({type:REQUEST_ORGANIZATIONS})
export const receiveOrganizations = (organizations: IOrganization[]) => ({
    organizations,
    type:RECEIVE_ORGANIZATIONS,
})

export const getOrganizations = (idToken:string) => {
    return((dispatch:any, getState:any):Promise<AnyAction>=>{
        dispatch(requestOrganizations());
        return new Promise((resolve,reject)=>{
            client.getOrganizations(idToken)
            .then((organizations:IOrganization[])=>{
                dispatch(receiveOrganizations(organizations))
            }).catch((err:AxiosError)=>{
                console.log(err)
            })
        })
    })
}
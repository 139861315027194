import { 
    LOGIN, 
    LOGOUT,
    RECEIVE_CURRENT_USER,
} from './constants'

import { AnyAction } from 'redux';

import { IUser } from '../_reducers/interfaces'

import { client } from '../Client'

export const logIn = (idToken:string) => ({type:LOGIN, idToken})
export const logOut = () => ({type:LOGOUT})

export const receiveCurrentUser = (user:IUser) => ({
    type: RECEIVE_CURRENT_USER,
    user,
})

export const getCurrentUser = (idToken:string) => {
    return((dispatch:any, getState:any):Promise<AnyAction>=>{
        return new Promise((resolve,reject)=>{
            client.getCurrentUser(idToken)
            .then((user:IUser)=>{
                dispatch(receiveCurrentUser(user))
                dispatch(logIn(idToken))
            }).catch((err:any)=>{
                console.log(err)
            })
        })
    })
}

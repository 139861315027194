export const DELETE_WISH = 'DELETE_WISH'
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER'
export const RECEIVE_INVITES = 'RECEIVE_INVITES'
export const RECEIVE_ORGANIZATIONS = 'RECEIVE_ORGANIZATIONS'
export const RECEIVE_MY_WISHES = 'RECEIVE_MY_WISHES'
export const REQUEST_ORGANIZATIONS = 'REQUEST_ORGANIZATIONS'
export const REQUEST_MY_WISHES = 'REQUEST_MY_WISHES'
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS'
export const CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const REQUEST_ORG_WISHES = 'REQUEST_ORG_WISHES'
export const RECEIVE_ORG_WISHES = 'RECEIVE_ORG_WISHES'
export const REQUEST_WISH = 'REQUEST_WISH'
export const RECEIVE_WISH = 'RECEIVE_WISH'
export const SET_DEFAULT_SELECTED = 'SET_DEFAULT_SELECTED'
export const CLEAR_DEFAULT_SELECTED = 'CLEAR_DEFAULT_SELECTED'
export const REQUEST_MY_WISH = 'REQUEST_MY_WISH'
export const RECEIVE_MY_WISH = 'RECEIVE_MY_WISH'
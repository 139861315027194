import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from "@auth0/auth0-react"

import { store } from './store'
import history from './history'

const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID!
const CALLBACK = process.env.REACT_APP_AUTH0_CALLBACK!

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="wwrld.auth0.com"
      clientId={CLIENT_ID}
      redirectUri={CALLBACK}
    >
      <App
        store={store}
        history={history}
      />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

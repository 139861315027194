import {
    CLEAR_BREADCRUMBS,
    SET_BREADCRUMBS,
} from '../_actions/constants'

// define default state

const defaultState: any[] = []

// reducer
export const breadcrumbs = (
    state=defaultState,
    action:any,
): any[] => {
    switch (action.type) {
        case CLEAR_BREADCRUMBS:
            return []
        
        case SET_BREADCRUMBS:
            return action.breadcrumbs
            
        default:
            return state
    }
}
import {FC} from "react";
import { useHistory } from 'react-router-dom';


import gift from '../../assets/red_gift_gold_ribbon.png'
import giftNutmeg from '../../assets/red_gift_nutmeg.png'

export const Logo: FC = () => {
    let history = useHistory()
    return(
    <div className="flex-shrink-0 flex items-center" onClick={()=>history.push('/')}>
        <img className="block lg:hidden h-8 w-auto" src={gift} alt="Workflow"/>
        <img className="hidden lg:block h-8 w-auto" src={giftNutmeg} alt="Workflow"/>
    </div>
    )
}

import {FC} from "react";

interface IProps {
    location?: string,
    url?: string,
}

export const WishLink: FC<IProps> = (props:IProps) => {
    const {location, url} = props
    
    if(!location && !url){
        return(
            null
        )
    }

    return (
        <a
            className="mt-1 text-blue-500 text-sm truncate"
            href={url? url : undefined}
            target="_blank"
            rel="noopener noreferrer"
        >{location?location:url?url:undefined}</a>
  )
};

import {
    RECEIVE_INVITES,
} from '../_actions'

// define default state

import { IInviteReducer } from './interfaces'

const defaultState: IInviteReducer = {
    isFetching: false,
    invites: [],
    lastUpdated: 0
}

// reducer
export const invites = (
    state=defaultState,
    action:any,
): IInviteReducer => {
    switch (action.type) {
        case RECEIVE_INVITES:
            return Object.assign({},state,{
                isFetching:false,
                invites: action.invites,
                lastUpdated: Date.now(),
            })

        default:
            return state
    }
}
import { combineReducers } from 'redux'

import { breadcrumbs } from './breadcrumbs'
import { invites } from './invites'
import { organizations } from './organizations'
import { user } from './user'
import { myWishes } from './myWishes'
import { orgWishes } from './orgWishes'
import { defaultSelected } from './defaultSelected'


const rootReducer = combineReducers({
    breadcrumbs,
    defaultSelected,
    invites,
    myWishes,
    organizations,
    orgWishes,
    user,
})

export { 
    rootReducer,
}
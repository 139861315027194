import { FC } from 'react';

interface IProps {
    text: string,
}

export const Subtitle:FC<IProps> = (props:IProps) => {
    return(            
        <h1>
            <span className="
                mt-8 block
                text-1xl text-left
                leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                {props.text}
            </span>
        </h1>
    )
}

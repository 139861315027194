import { FC, useEffect } from 'react';
import { Nav } from '../components'

import { Paragraph } from '../components'
import { Title } from '../components'
import { Subtitle } from '../components'
import { Microtitle } from '../components'



export const PrivacyScene:FC = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    return(
        <div>
            <Nav/>
            <div className="relative py-20 bg-white overflow-hidden">        
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <Title text="Privacy" />
                        <i className="mt-8 text-xl text-gray-500 leading-8">Last updated December 2020</i>
                        <Paragraph text="NutmegWishlist.com is a free service for sharing wishlists and gift ideas with family and friends."/>
                        <Paragraph text="NutmegWishlist.com (the “Company” or “Nutmeg”) is committed to maintaining robust privacy protections for its users. Our Privacy Policy (“Privacy Policy”) helps you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may opt out of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used." />
                        <Paragraph text="By accepting our Privacy Policy and Terms of Use, you consent to our collection, storage, use and disclosure of your personal information as described in this Privacy Policy." />
                        <Subtitle text="Information We Collect" />
                        <Paragraph text="We collect “Non-Personal Information” and “Personal Information.”" />
                        <Paragraph text="Non-Personal Information includes information that cannot be used to personally identify you, such as anonymous usage data, IP addresses, general demographic information we may collect, referring/exit pages and URLs, platform types, cursor movement, preferences you submit and preferences that are generated based on the data you submit and number of clicks." />
                        <Paragraph text="Personal Information includes your name and email." />
                        <Subtitle text="Correcting Personal Information" />
                        <Paragraph text="A member may update their Personal Information by logging in and visiting the settings pages on web or mobile. Or they may request a change in writing by emailing support[at]nutmegwishlist.com." />
                        <Subtitle text="Information collected via Technology" />
                        <Paragraph text="To activate the Service you do not need to submit any Personal Information other than your name and email address. To use the Service thereafter, you do not need to submit further Personal Information. However, in an effort to improve the quality of the Service, we track and collect Non-Personal Information provided to us by your browser or by our software application when you view or use the Service." />
                        <Paragraph text="When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device’s unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, your location information and other statistics." />
                        <Paragraph text="The Company also may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. We use cookies to collect the information we need to optimize the product/Merchant links we monetize/create, customize the Service to each users preferences, and improve the Service we provide to third-party merchants and advertisers. We also may synchronize anonymous cookie identifiers with third parties in order to enrich the Service and offer personalized experiences across the web. The techniques used do not collect any Personally Identifiable Information." />
                        <Paragraph text="We may use third-party services such as Google Analytics that collect, monitor and analyze this Non-Personal Information in order to increase our Service's functionality. These third-party service providers have their own privacy policies addressing how they use such information." />
                        <Paragraph text="From time to time Nutmeg may record individual user interaction with various user interface elements within the Service. This information is used internally to correct deficiencies and improve the member experience." />
                        <Subtitle text="Information you provide us by registering for an account" />
                        <Paragraph text="In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address and creating a password. By registering, you are authorizing us to collect, store and use your email address in accordance with this Privacy Policy." />
                        <Subtitle text="How We Use and Share Information" />
                        <Microtitle text="Personal Information" />
                        <Paragraph text="Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the Company, such as the servers for our email communications who are provided access to user’s email address for purposes of sending emails from us. Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy." />
                        <Paragraph text="In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact members in response to questions, solicit feedback from members, provide technical support, send transactional emails, and inform members about service upgrades and service usage tips." />
                        <Microtitle text="Non-Personal Information" />
                        <Paragraph text="In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. We also use IP addresses as part of our efforts to reduce fraud and identify non-human browsing patterns. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers, and other third parties at our discretion." />
                        <Paragraph text="You grant to Nutmeg a nonexclusive, perpetual, worldwide license to combine the User-Provided Content from all Nutmeg members, to create anonymous, aggregate data with no individual identification exposed for purposes of exposing trends, popular items, and other metadata whether now known or hereafter devised." />
                        <Microtitle text="International Transfer" />
                        <Paragraph text="Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction." />
                        <Paragraph text="If you are located outside the United States and choose to provide information to us, please note that we transfer the information, including Personal Information, to the United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer." />
                        <Microtitle text="Notice to California Residents" />
                        <Paragraph text="Pursuant to California Civil Code 1798.115" />
                        <Paragraph text="Effective January 1, 2020, the California Consumer Privacy Act (“CCPA”) grants California residents various rights regarding their personal information relating to that resident or household that is held or collected by a business." />
                        <Microtitle text="Your CCPA Rights" />
                        <Paragraph text="CCPA provides California residents with particular rights regarding their personal information collected by businesses. If you are a California resident, you may exercise the following rights regarding your personal information:" />
                        <Paragraph text="Right to Know You may request an overview of the personal information we have collected about you in your account. You may request a list of the companies with which we share personal information in the course of providing our service to you." />
                        <Paragraph text="Right to Delete You may request deletion of the personal information Nutmeg has collected about you in your account, for any reason. However, we may retain certain information as required or permitted by applicable law." />
                        <Paragraph text="To make such a request, please send an email to support[at]nutmegwishlist.com.com or write us:" />
                        <Paragraph text="Notice to Residents of the European Union" />
                        <Paragraph text="For those within the European Economic Area (EEA), the General Data Protection Regulation (GDPR) that went into effect on May 25, 2018 affords you certain European Privacy Rights to your personal data." />
                        <Paragraph text="These rights are as follows: (a) the right to access any personal information being held about you, (b) the right to have any personal information being held about you permanently deleted (provided that the information is not required to comply with a legal obligation or claim), (c) the right to promptly rectify any inaccurate personal information, (d) the right to restrict processing of your personal information (provided that processing is not required to comply with a legal obligation or claim), (e) the right to request transfer of your personal information directly to a third party if technically feasible, and (f) the right to object to the processing of any personal data at any time." />
                        <Subtitle text="Right to Object to Direct Marketing" />
                        <Paragraph text="We only send marketing communications to members we believe to be located in the EEA with your prior consent, and you may opt-out of such communications at any time by clicking the “unsubscribe” link found within email updates or by changing your communication preferences in the service. You will continue to receive essential account-related information, such as group membership requests, even if you unsubscribe from marketing communications emails." />
                        <Paragraph text="All members can object to the processing of personal information for the purposes of direct marketing at any time, with no fee to do so, by contacting support[at]nutmegwishlist.com." />
                        <Subtitle text="Use of Collected Information" />
                        <Paragraph text="Nutmeg will only use the information collected from and about you on the following lawful grounds:" />
                        <Paragraph text="Use is necessary to perform a contract we have entered into or are about to enter into with you;" />
                        <Paragraph text="Use is necessary for our legitimate business interests;" />
                        <Paragraph text="Compliance with a legal or regulatory obligation is required; or" />
                        <Paragraph text="Where consent has been provided (which can be withdrawn at any time)" />
                        <Paragraph text="If none of these conditions will be satisfied by the use of your personal information, then such information is permanently deleted from our system." />
                        <Paragraph text="In addition to the personal information you provide to us, we may also use publicly available information as well as information gathered from you by third parties to improve your experience using our service, inform you about new products or promotions, or for any other legitimate business purpose." />
                        <Subtitle text="Right to Erasure" />
                        <Paragraph text="All Nutmeg members have the right to request that any and all Personally Identifiable Information (PII) associated with their account be erased. Once a data subject access request (DSAR) is filed and member identity is verified by email, the Company must respond to and fulfill such request within 30 days. When an account is “erased,” all PII associated with the account held by Nutmeg is permanently deleted." />
                        <Subtitle text="Third-Party Partners" />
                        <Paragraph text="When Nutmeg members sign up for our service, we share member data with partners that help us fulfill the service obligation to the member. The primary type of service we share PII with are specialized email platforms that operate under our direction. These partners do not resell your PII." />
                        <Paragraph text="Nutmeg members or guests who elect to have their PII deleted from our system may do so by contacting support[at]nutmegwishlist.com." />
                        <Subtitle text="Right to Access" />
                        <Paragraph text="All Nutmeg members have the undeniable right to ask about and obtain a complete and accurate report on the data being stored about them. If you would like to request a report on the data we are storing about you, email us at support[at]nutmegwishlist.com" />
                        <Subtitle text="Who Can I Contact at Nutmeg Regarding Data Protection Issues?" />
                        <Paragraph text="Nutmeg has a designated Data Protection Officer to assist with data privacy and data protection issues. You may contact them by contacting support[at]nutmegwishlist.com or writing the company at the mailing address shown below." />
                        <Paragraph text="If you feel that we are not adhering to the GDPR or our Privacy Policy at any time, please let us know. Also, you have the right to file a complaint with an EU Data Protection Authority." />
                        <Subtitle text="Behavioral Remarketing" />
                        <Paragraph text="Nutmeg may use remarketing services on third party web sites from organizations like Google Adwords to advertise to you after you visited our Site. We, and our third-party vendors, do this through the use of cookies to inform, optimize, and serve ads based on your past website visit history or based on item keywords on your lists. Google AdWords remarketing service is provided by Google Inc. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: http://www.google.com/intl/en/policies/privacy/" />
                        <Subtitle text="Sale or Transfer of Business" />
                        <Paragraph text="In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy and that any acquirer of our assets may continue to process your information as set forth in this Privacy Policy." />
                        <Subtitle text="How We Protect Your Information" />
                        <Paragraph text="We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls, and HTTPS or SSL security standards. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using or accessing our Site, you acknowledge that you understand and agree to assume these risks." />
                        <Subtitle text="Your Rights Regarding Use of Your Personal Information" />
                        <Paragraph text="You have the right at any time to prevent us from contacting you with promotional communications. When we send a promotional communication to a member, the member can opt-out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail. You can also indicate that you do not wish to receive birthday reminder communications from us in the “Settings” section of the Site. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing or opting out in the Settings section of the Site, we may continue to send you administrative emails including, for example, group membership requests, member-initiated reminder emails, or periodic updates to our Privacy Policy." />
                        <Subtitle text="Links to Other Websites" />
                        <Paragraph text="As part of the Service, you may submit or follow web links to other websites or applications. We are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Service. Therefore, this Privacy Policy does not apply to your use of a third-party website accessed by selecting a link on our Site. To the extent that you access or use the Site through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them." />
                        <Subtitle text="Children's Privacy" />
                        <Paragraph text="Only persons age 13 or older have permission to access the Site. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you learn that your Children have provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers. Nutmeg provides child accounts as a simple way for parents to manage an account on behalf of a child to include lists and preferences in their Giftster group." />
                        <Subtitle text="Contact Us" />
                        <Paragraph text="If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending an email to support[at]nutmegwishlist.com." />
                    </div>
            </div>
            </div>
        </div>
    )
}

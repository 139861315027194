import { FC } from 'react';
import { IMembership } from '../../../services/_reducers/interfaces';

import { RemoveMemberButton } from './RemoveMemberButton'

import { Link } from 'react-router-dom'

interface IProps {
    membership: IMembership,
    getMemberships: (groupId:string)=>void
}

export const MemberCard:FC<IProps> = (props:IProps) => {
    const { membership } = props
    const groupId = membership.organization.id
    const user = membership.user

    return(            
        <li className="
        col-span-1
        flex shadow-sm rounded-md 
        align-center
        border
        border-gray-200

        hover:bg-green-800
        text-gray-900
        hover:text-gray-200
        "
        key={membership.id}
        >
            <Link 
                to={`/user/${groupId}/${user.id}`}
                className="text-left p-4 font-medium shadow-sm rounded-md w-full">
                <span>{user.name}</span>
            </Link>
            <RemoveMemberButton
                membership={membership}
                getMemberships={props.getMemberships}
            />
        </li>
    )
}

import { FC } from 'react'

import { Route, Router }from 'react-router-dom'
import { Provider } from 'react-redux'

import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

import { getCurrentUser } from './services/_actions'

import { 
  AddWishScene,
  Callback,
  CreateGroupScene,
  EditWishScene,
  GroupScene,
  IdeasScene,
  InviteScene,
  LandingScene,
  ManageGroupScene,
  MyWishesScene,
  UserWishesScene,
  SelectGroupScene,
  PrivacyScene,
  TermsScene,
  ProfileScene,
} from './scenes'

interface IAppProps {
  store:any;
  history:any;
}

export const App:FC<IAppProps> = (props:IAppProps) => {
  
  const { store, history } = props
  
  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  
  useEffect(()=>{
      const getClaims = async () => {
          let c = await getIdTokenClaims()
          console.log(c)
          store.dispatch(getCurrentUser(c.__raw))
      }
      if(isAuthenticated) {
          getClaims()
      }},[isAuthenticated, getIdTokenClaims, store])

  return (
    <Provider store={store}>
      <Router history={history}>
        <Route
          path="/"
          component={LandingScene}
          exact={true}
        />
        <Route
          path="/add-wish/:recipientId?"
          component={AddWishScene}
          exact={true}
        />
        <Route
          path="/create-group"
          component={CreateGroupScene}
          exact={true}
        />
        <Route
          path="/edit-wish/:wishId?"
          component={EditWishScene}
          exact={true}
        />
        <Route
          path="/invite/:groupId"
          component={InviteScene}
          exact={true}
        />
        <Route
          path="/privacy"
          component={PrivacyScene}
          exact={true}
          />
          <Route
            path="/terms"
            component={TermsScene}
            exact={true}
          />
          <Route
            path="/my-wishes"
            component={MyWishesScene}
            exact={true}
          />
          <Route
            path="/groups"
            component={SelectGroupScene}
            exact={true}
          />
          <Route
            path="/group/:groupId"
            component={GroupScene}
            exact={true}
          />
          <Route
            path="/group/:groupId/manage"
            component={ManageGroupScene}
            exact={true}
          />
          <Route
            path="/user/:groupId/:userId"
            component={UserWishesScene}
            exact={true}
          />
          <Route
            path="/ideas"
            component={IdeasScene}
            exact={true}
          />
          <Route
            path="/callback"
            component={Callback}
            exact={true}
          />
          <Route
            path="/profile"
            component={ProfileScene}
            exact={true}
          />
      </Router>
    </Provider>
  );
}

export default App;

import { FC } from 'react';

interface IProps {
    children: any,
}

export const AppHeader:FC<IProps> = (props:IProps) => {
    return(            
        <header className="pt-20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-xl sm:text-3xl font-bold leading-tight text-gray-900">
                {props.children}
            </h1>
            </div>
        </header>       
    )
}

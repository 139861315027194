import {FC} from "react";
import { IUserReducer, IWish } from "../../../services/_reducers/interfaces";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cx from 'classnames' 
import { MarkPurchased } from './MarkPurchased'
import { DestroyWish } from "./DestroyWish";
import { getMyWish } from "../../../services/_actions/myWishes";


interface IProps {
    wish: IWish,
    mine?: boolean,
}

interface IRootState {
    user: IUserReducer
}

export const WishButtons: FC<IProps> = (props:IProps) => {
    const {mine, wish} = props
    const user = useSelector((state:IRootState) => state.user)
    const history = useHistory()
    const dispatch = useDispatch()

    
    // style
    const disableEdit = mine?false:!(wish.creator.id === user.id)
    const editClasses = cx({
        'cursor-not-allowed': disableEdit,
        'bg-gray-200': disableEdit,
        'hover:bg-gray-50': !disableEdit,
    })

    return (
        <div className="grid grid-cols-3 divide-x divide-gray-200">
        <MarkPurchased wish={wish} mine={mine}/>
        <button
          type="button"
          className={`
            relative inline-flex items-center
            px-4 py-2
            text-sm font-medium text-gray-700
            focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
            ${editClasses}
            `}
          disabled={disableEdit}
          title="Edit Wish"
          onClick={()=>{
            if(user.idToken){
              dispatch(getMyWish(user.idToken, wish.id))
            }
            history.push(`/edit-wish/${wish.id}`)
          }}
          >
            <svg className="h-5 w-5 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
            </svg>
        </button>
        <DestroyWish wish={wish} mine={mine}/>
      </div>
  )
};

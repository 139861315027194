import { FC } from 'react';
import { Nav, Subtitle, Title } from '../components'
import { FooterSection } from './LandingScene/sections/FooterSection';

export const IdeasScene:FC = () => {

    return(
        <div>
            <Nav/>
            <div className="relative py-20 bg-white overflow-hidden">        
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 h-full">

                        <Title text="Need inspiration?" />
                        <Subtitle text="Browse gift ideas here." />

                        <div className="inline-flex justify-left w-full pt-12">
                            <iframe 
                                src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=21&l=ur1&category=topgiftideas&banner=1RZA3ATYBAJCS3X46SG2&f=ifr&linkID=4101d245aa8d8fb592cba779c527d830&t=nutmegwishl06-20&tracking_id=nutmegwishl06-20"
                                width="125"
                                height="125"
                                scrolling="no"
                                title="amazon">

                                </iframe>
                        </div>
                        <div className="alignleft">
                        <script type="text/javascript">
                            amzn_assoc_ad_type = "banner";
                        amzn_assoc_marketplace = "amazon";
                        amzn_assoc_region = "US";
                        amzn_assoc_placement = "assoc_banner_placement_default";
                        amzn_assoc_campaigns = "topgiftideas";
                        amzn_assoc_banner_type = "category";
                        amzn_assoc_isresponsive = "true";
                        amzn_assoc_banner_id = "0D4RWFY28JQA0H12NC82";
                        amzn_assoc_tracking_id = "nutmegwishl06-20";
                        amzn_assoc_linkid = "0cb6d2e71bc641dc972bde18a6c3afbe";
                        </script>
                        <script src="//z-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&Operation=GetScript&ID=OneJS&WS=1"></script>
                        </div>

                        <Subtitle text=". . .  just kidding!" />
                        <Subtitle text="Our recommendation platform is coming soon." />
                    </div>
            </div>
            </div>
            <FooterSection/>
        </div>
    )
}

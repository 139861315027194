import { FC } from 'react';
import { useAuth0} from '@auth0/auth0-react'
import { useEffect } from 'react'
import { getMyWishes } from '../services/_actions'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { AppContent, AppHeader, AppToolbar, Button, 
    Nav, Spinner, TogglePrior, WishCard } from '../components'
import { IMyWishesReducer, IUserReducer } from '../services/_reducers/interfaces';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom'

interface IProps {
    dispatch: any;
    myWishes: any;
}

interface IRootSelector {
    user: IUserReducer,
    myWishes: IMyWishesReducer
}

export const MyWishesScene:FC<IProps> = (props: IProps) => {
    // services
    const { user, myWishes } = useSelector((state:IRootSelector) => state)
    const history = useHistory()
    const { isAuthenticated } = useAuth0();
    const dispatch = useDispatch()

    // state
    const [prior, setPrior] = useState(false)



    useEffect(()=>{
        if(isAuthenticated) {
            dispatch(getMyWishes(user.idToken!))
        }},[isAuthenticated, dispatch, user])

    const thisYear = new Date().getFullYear()

    const filteredWishes = prior?
        myWishes.wishes.filter(w=>w.year!==thisYear)
        :
        myWishes.wishes.filter(w=>w.year===thisYear)

    if(!isAuthenticated){
        return(<Redirect to="/"/>)
    }

    return(
        <>
        <Nav/>
        <div className="py-10 h-screen">
        <AppHeader>My Wishes {prior?"(Prior Years)":`(${thisYear})`}</AppHeader>
            <AppToolbar>
                <span className="flex justify-left space-x-6 items-center">
                    <TogglePrior prior={prior} setPrior={setPrior}/>
                    {
                        myWishes.isFetching?
                        <span className="flex items-center space-x-1 bg-green-100 rounded-2xl px-4 py-1">
                            <Spinner/>
                            <p>Refreshing</p>
                        </span>:null
                    }
                </span>
                <Button onClick={()=>{history.push(`/add-wish/${user.id}`)}}>
                    Add Wish
                </Button>
            </AppToolbar>
            <AppContent>
                {
                    (myWishes.wishes.length === 0 && myWishes.isFetching)?
                        <div className="w-full flex justify-center py-12">
                            <Spinner/>
                        </div>
                    :
                        <ul className="grid grid-cols-1 p-4 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            {filteredWishes.map(w=><WishCard wish={w} mine={true}/>)}
                        </ul>
                }

            </AppContent>
        </div>
        </>
    )
}
import {FC, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { MobileMenuButton } from './MobileMenuButton'
import { MobileMenu } from './MobileMenu'
import { NavLink } from './NavLink'
import { ProfileDropdown } from './ProfileDropdown'
import { Logo } from './Logo'

const Nav: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [showMobileMenu, setShowMobileMenu]  = useState(false);

  
  return (
    <nav className="bg-green-800 fixed w-full z-40">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <MobileMenuButton toggle={() => setShowMobileMenu(!showMobileMenu)}/>
          <div className="flex">
            <Logo/>
            <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
              {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
              <NavLink to="/" >Home</NavLink>
              <NavLink to="/ideas">Ideas</NavLink>
              {isAuthenticated && (
                <>
                  <NavLink 
                    to="/my-wishes"
                    >My Wishes</NavLink>
                  <NavLink to="/groups">Groups</NavLink>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center">
            {!isAuthenticated && (
                <div className="flex-shrink-0">
                <button 
                    type="button"
                    className="
                        relative
                        inline-flex
                        items-center
                        px-4 py-2 border border-transparent shadow-sm
                        text-sm font-medium rounded-md text-white
                        hover:bg-gray-100
                        hover:text-gray-900
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                    onClick={loginWithRedirect}
                    >
                    <span>Log In or Sign Up</span>
                </button>
                </div>
            )}

            { isAuthenticated && (
                <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                    {/* <button className="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">View notifications</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                        </svg>
                    </button> */}
        
                    <ProfileDropdown/>
                </div>
            )}
          </div>
        </div>
      </div>
    
      <MobileMenu show={showMobileMenu}/>

    </nav>
    
  )
};

export default Nav;
import { FC } from 'react';
import { useAuth0} from '@auth0/auth0-react'
import { useEffect } from 'react'
import { getOrgWishes } from '../services/_actions'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { AppContent, AppHeader, AppToolbar, Button, Nav, Spinner, TogglePrior, WishCard } from '../components'
import { IWishReducer, IUserReducer, IOrgWishesReducer, IOrgReducer, IUser } from '../services/_reducers/interfaces';
import { useDispatch } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router';


interface IRootSelector {
    user: IUserReducer,
    organizations: IOrgReducer,
    orgWishes: IOrgWishesReducer
}

interface IParams {
    groupId: string;
    userId: string;
}


export const UserWishesScene:FC = () => {
    // initialize services
    const { user, orgWishes, organizations } = useSelector((state:IRootSelector) => state)    
    const history = useHistory()
    const { groupId, userId } = useParams<IParams>()
    const { isAuthenticated } = useAuth0();
    const dispatch = useDispatch()    

    // initialize state
    const [prior, setPrior] = useState(false)
    const [recipientName, setRecipientName] = useState("")
    const [filteredWishes, setFilteredWishes] = useState<IWishReducer[]>([])


    useEffect(()=>{
        if(isAuthenticated) {dispatch(getOrgWishes(user.idToken!, groupId))}
    },[isAuthenticated, dispatch, user, groupId])

    const thisYear = new Date().getFullYear()

    
    
    useEffect(()=>{
        const group = organizations.organizations.find(o=>o.id === groupId)

        console.log(group)

        const recipient: IUser | boolean | undefined = 
            group?.members?.map(m=>m.id).some(m => m === userId) &&
            group?.members?.find(m=>m.id === userId)

        console.log(recipient)

        if(recipient){
            setRecipientName(recipient.name)
            const fw = prior?
                orgWishes.wishes.filter(w=>w.wish.year!==thisYear && w.wish.recipient.id === userId)
                :
                orgWishes.wishes.filter(w=>w.wish.year===thisYear && w.wish.recipient.id === userId)
            setFilteredWishes(fw)
        }
        
    }, [userId, prior, orgWishes, groupId, organizations, thisYear])

    if(!isAuthenticated){
        return(<Redirect to="/"/>)
    }

    return(
        <>
            <Nav/>
            <div className="py-10 h-screen">
            <AppHeader>{`${recipientName}`} Wishes {prior?"(Prior)":`(${thisYear})`}</AppHeader>
                <AppToolbar>
                    <span className=" block sm:flex justify-left sm:space-x-6 items-center">
                        <Button
                            onClick={()=>{history.push(`/group/${groupId}`)}}
                        >
                                <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                </svg>
                        </Button>
                        <TogglePrior prior={prior} setPrior={setPrior}/>
                        {
                            orgWishes.isFetching?
                            <span className="flex items-center space-x-1 bg-green-100 rounded-2xl my-2 px-4 py-1">
                                <Spinner/>
                                <p>Refreshing</p>
                            </span>:null
                        }
                    </span>
                    <Button onClick={()=>{history.push(`/add-wish/${userId}`)}}>
                        Add Wish
                    </Button>
                </AppToolbar>
                <AppContent>
                    {
                        orgWishes.isFetching && orgWishes.wishes.length === 0?
                        <div className="w-full py-20 flex justify-center">

                            <Spinner/>
                        </div>
                        :
                        <ul className="grid grid-cols-1 p-4 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            {filteredWishes.map(w=><WishCard wish={w.wish} mine={false}/>)}
                        </ul>
                    }
                </AppContent>
            </div>
        </>
    )
}
import { FC } from 'react'

import { Paragraph } from '../components'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { logIn } from '../services/_actions'


export const Callback:FC = ()=> {
    const dispatch = useDispatch()
    const { isAuthenticated, getIdTokenClaims } = useAuth0()
    let history = useHistory()
    const getClaims = async () => {
        let c = await getIdTokenClaims()
        dispatch(logIn(c._raw))
        history.push("/")
    }

    const onMount = () => {
        if(isAuthenticated) {
            getClaims()
        }
    }

    useEffect(onMount)

    return(
        <div>
            <div className="relative py-16 bg-white overflow-hidden">        
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <Paragraph text="Loggin In ..."/>
                    </div>
            </div>
            </div>
        </div>
    )
}

export default Callback

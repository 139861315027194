import { FC, useEffect } from 'react';
import { Nav } from '../../components'
import { HeroSection } from './sections/HeroSection'
import { LearnMoreSection } from './sections/LearnMoreSection'
import { FooterSection } from './sections/FooterSection'
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

import { IUser } from '../../services/_reducers/interfaces'
import { getMyWishes, getOrganizations } from '../../services/_actions';

interface IState {
    user: IUser
}

export const LandingScene:FC = () => {
    const { user } = useSelector((state:IState)=>state)
    const {isAuthenticated} = useAuth0()
    const dispatch = useDispatch()
    useEffect(()=>{
        if(isAuthenticated){
            dispatch(getMyWishes(user.idToken))
            dispatch(getOrganizations(user.idToken))
        }
    },[isAuthenticated, dispatch, user])

    return(
        <div>
            <Nav/>
                <HeroSection/>
                <LearnMoreSection/>
                <FooterSection/>
            
        </div>
    )
}

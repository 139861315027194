
import { FC } from 'react';
import cx from 'classnames'


interface IProps {
    prior: boolean,
    setPrior: (value:boolean)=>void;
}

export const TogglePrior:FC<IProps> = (props:IProps) => {
    const { prior, setPrior } = props
    
    const currentBg = cx({
        'bg-white': prior,
        'bg-blue-300': !prior,
    })

    const priorBg = cx({
        'bg-white': !prior,
        'bg-blue-300': prior,
    })

    return(            

        <span className="relative z-0 inline-flex shadow-sm rounded-md mx-2 ">
            <button
                type="button"
                className={`relative w-20 inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 ${currentBg} text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                onClick={()=>setPrior(false)}
                >
                Current
            </button>
            <button
                type="button"
                className={`-ml-px w-20 relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 ${priorBg} text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                onClick={()=>setPrior(true)}
                >
                Prior
            </button>
        </span>
    )
}

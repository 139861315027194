import { FC } from 'react';

interface IProps {
    children: any,    
}

export const AppContent:FC<IProps> = (props:IProps) => {
    return(            
      <main className="h-full">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 h-full">
          {/* <!-- Replace with your content --> */}
          <div className="px-4 py-8 sm:px-0 h-full">
            <div className="border border-dashed border-gray-200 rounded-lg ">
              {props.children}
            </div>
          </div>
          {/* end replace */}
        </div>
      </main>
       
    )
}

import { FC } from 'react';
import { useState } from 'react'

import cx from 'classnames'

interface IProps {
    text: string,
    children:any;
}

export const AccordianSection:FC<IProps> = (props:IProps) => {

    const [open, setOpen ] = useState(false)

    const openClasses = cx({
        "-rotate-180": open,
        "rotate-0": !open,
    })

    const contentClasses = cx({
        "hidden": !open,
        "block": open,
    })

    return(            
    <div className="max-w-7xl mx-auto rounded-md border-b-2">
            <div>
                <dt className="text-lg">
                <button
                    onClick={()=>{setOpen(!open)}}
                    className="text-left w-full flex justify-between rounded-lg items-start text-gray-400 py-6 px-4  sm:px-6 lg:px-8">
                    <span className="font-medium text-gray-900">
                    {props.text}
                    </span>
                    <span className="ml-6 h-7 flex items-center">
                    <svg className={`h-6 w-6 transform ${openClasses}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    </span>
                </button>
                </dt>
                <div className={`${contentClasses} mt-2 px-4`}>
                    {props.children}
                </div>
            </div>
    </div>

    )
}

import {
    LOGIN,
    LOGOUT,
    RECEIVE_CURRENT_USER,
} from '../_actions'

// define default state

import { IUserReducer } from './interfaces'

const defaultState: IUserReducer = {
    email: "",
    id: 0,
    idToken: undefined,
    label:"",
    loggedin: false,
    name:"",
    minors:[],
}

// reducer
export const user = (
    state=defaultState,
    action:any,
): IUserReducer => {
    switch (action.type) {
        case LOGIN:
            return Object.assign({},state,{
                ...state,
                loggedin: true,
                idToken: action.idToken
            })
        
        case LOGOUT:
            return Object.assign({},state,{
                ...state,
                loggedin: false,
                idToken: undefined,
            })
        
        case RECEIVE_CURRENT_USER:
            return Object.assign({},state,{
                ...state,
                id: action.user.id,
                email: action.user.email,
                label: action.user.name,
                name: action.user.name,
                minors: action.user.minors,
            })
        default:
            return state
    }
}
import {
    DELETE_WISH,
    REQUEST_ORG_WISHES,
    RECEIVE_ORG_WISHES,
    REQUEST_WISH,
    RECEIVE_WISH,
} from '../_actions'

import {
    IOrgWishesReducer,
    IShare,
    IWish,
    IWishReducer,
} from './interfaces'


export function orgWishes(state:IOrgWishesReducer={isFetching:false, wishes:[]}, action:any){
    switch(action.type){
        case DELETE_WISH:
            return Object.assign({},state,{
                ...state,
                wishes:state.wishes.filter((wish)=>wish.wish.id!==action.wishId)
            })
        
        case REQUEST_ORG_WISHES:
            return Object.assign({},state,{
                ...state,
                isFetching:true,
            })

        case RECEIVE_ORG_WISHES:
            const receivedWishIds = action.wishes.map((wish:IWish)=>wish.id)
            const unchangedWishes = state.wishes.filter((wish:IWishReducer)=>(!receivedWishIds.includes(wish.wish.id)))
            const updatedWishes = action.wishes.map((wish:IWish)=>{
                const shares = wish.shares!.map((share:IShare)=>share.organization.id)
                return({
                    isFetching:false,
                    shares,
                    wish,
                })
            })
            return Object.assign({},state,{
                isFetching: false,
                wishes: [
                    ...unchangedWishes,
                    ...updatedWishes
                ]
            })
        
        case REQUEST_WISH:

            let j = state.wishes.findIndex(value=>value.wish.id===action.wishId)

            return Object.assign({},state,{
                isFetching:false,
                wishes: [
                    ...state.wishes.slice(0,j),
                    {
                        isFetching:true,
                        wish: state.wishes[j].wish,
                        shares: state.wishes[j].shares,
                    },
                    ...state.wishes.slice(j+1)
                ]
            })

        case RECEIVE_WISH:

                let r = state.wishes.findIndex(value=>value.wish.id===action.wish.id)
                return Object.assign({},state,{
                isFetching:false,
                wishes: [
                    ...state.wishes.slice(0,r),
                    {
                        isFetching:false,
                        wish:action.wish,
                        shares: action.wish.shares.map((s:IShare)=>s.organization.id)
                    },
                    ...state.wishes.slice(r+1)
                ]
            })

        default:
            return state
    }
}
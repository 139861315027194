import { FC } from 'react';
import { Nav } from '../components'

import { Paragraph } from '../components'
import { Title } from '../components'
import { Subtitle } from '../components'
import { Microtitle } from '../components'

import { useEffect } from 'react'

export const TermsScene:FC = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])


    return(
        <div>
            <Nav/>
            <div className="relative py-16 bg-white overflow-hidden">        
                <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                        <Title text="Terms of Use" />
                        <i className="mt-8 text-xl text-gray-500 leading-8">Last updated December 2020</i>
                        <Subtitle text="Welcome to NutmegWishlist.com (“Service”)." />
                        <Paragraph text="Nutmeg allows you create wish lists and gift preference profiles to share with family and friends." />
                        <Paragraph text="The following Terms of Use are a legal agreement between you and NutmegWishlist.com (“Nutmeg”) and apply to you whether or not you are a registered member of the Service or a visitor just browsing the website Nutmeg.com, or one of its affiliated URLs or Apps (collectively, the “Site”). Please review the following terms carefully. Your access or use of the Site signifies that you have read, understand, and agree to be bound by these Terms of Use, including the following policies, which are incorporated in this agreement by reference:" />
                        <Paragraph text="Privacy Policy (which explains how we collect, use, and disclose information that pertains to your privacy)" />
                        <Paragraph text="If you do not agree to these Terms of Use, then you have no right to access or use the Site, or any content contained on it." />
                        <Subtitle text="Registration" />
                        <Microtitle text="Accounts; Password" />
                        <Paragraph text="In order to access some of the features of the Site, you will have to create an account (“Account”). During the registration process, you will be required to provide certain information. You represent and warrant to Nutmeg that all information you submit when you create your Account is accurate and complete. If Nutmeg has reason to believe that your Account information is untrue, inaccurate, out-of-date, or incomplete, Nutmeg reserves the right, in its sole and absolute discretion, to suspend or terminate your Account." />
                        <Paragraph text="You are solely responsible for your account and the activity that occurs while signed in to or while using your account, whether authorized by you or not. You must keep your Account information secure, including without limitation your user number/login and password. You must notify Nutmeg immediately of any breach of security or unauthorized use of your Account. Nutmeg is not responsible for any liabilities, losses, or damages arising out of the unauthorized use of your number/login, password or account." />
                        <Subtitle text="Children Under 13" />
                        <Paragraph text="You must be at least 13 years old to register for and use the Service. To comply with the Children's Online Privacy Protection Act (COPPA), children under 13 years of age should not open their own accounts on this service. Wish lists for children under 13 years of age may be maintained by a parent or other adult in their own account in the child account section, or an account they open and maintain on behalf of the child under 13 years old." />
                        <Subtitle text="Use Restriction" />
                        <Paragraph text="Without limiting anything in these Terms of Use, your permission to access and use the Site is conditioned on your agreement that you will not:" />
                        <Paragraph text="use the Site to abuse, harass, threaten, intimidate, or invade the privacy of any other person or entity;" />
                        <Paragraph text="use the Site to impersonate or misrepresent your affiliation with any person or entity;" />
                        <Paragraph text="use the Site for any unlawful purpose or for the promotion of illegal activities;" />
                        <Paragraph text="post to, or link from the Site anything that:" />
                        <Paragraph text="infringes the copyright or intellectual property right of others, or rights of publicity or privacy;" />
                        <Paragraph text="is defamatory, obscene, or pornographic;" />
                        <Paragraph text="is fraudulent, false, misleading, or deceptive;" />
                        <Paragraph text="promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;" />
                        <Paragraph text="violates, or encourages any conduct that would violate, any applicable law or regulation, would give rise to civil liability, or otherwise violate these Terms of Use;" />
                        <Paragraph text="use the Site to post advertising, promotional materials, or other forms of solicitation." />
                        <Paragraph text="use the Site or any of its content for any commercial purpose." />
                        <Paragraph text="Further, under no circumstances may you:" />
                        <Paragraph text="use, display, mirror, frame, or utilize framing techniques to enclose the Site, or any content or individual element within the Site;" />
                        <Paragraph text="access, tamper with, or use non-public areas of the Site, Nutmeg’s computer systems, or the technical delivery systems of Nutmeg’s providers; attempt to probe, scan, or test the vulnerability of any Nutmeg system or network or breach any security or authentication measures;" />
                        <Paragraph text="avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure implemented by Nutmeg or any of Nutmeg’s providers or any other third party (including another user) to protect the Site or Nutmeg’s Content;" />
                        <Paragraph text="attempt to download Nutmeg Content from the Site through the use of any engine, software, tool, agent, device, or mechanism (including spiders, robots, crawlers, data mining tools, or the like) other than those generally available third party web browsers for general consumer use (such as Microsoft Internet Explorer, Mozilla Firefox, Safari or Opera);" />
                        <Paragraph text="attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Site, or its content;" />
                        <Paragraph text="interfere or attempt to interfere with the proper functioning of the Service;" />
                        <Paragraph text="not to use this site to collect or harvest personal information about other participants at this site;" />
                        <Paragraph text="make any automated use of the system, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;" />
                        <Paragraph text="create accounts for the purpose of creating public lists with back links for link farm purposes" />
                        <Paragraph text="bypass any robot exclusion headers or other measures we take to restrict access to the Service or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data; or" />
                        <Paragraph text="encourage or enable any other individual to do any of the foregoing." />
                        <Paragraph text="You acknowledge that any violations of the provisions of this Section are subject to injunctive relief and you agree to personal liability on behalf of all participants to the violations regardless of entity affiliation, for all related attorney fees, and legal costs." />
                        <Subtitle text="User Content" />
                        <Paragraph text="The Site allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ('User Content'). You are solely responsible for the User Content that you post, upload, link to or otherwise make available via the Site, including its legality, reliability, and appropriateness." />
                        <Subtitle text="User Content License" />
                        <Paragraph text="By posting any User Content via the Site, you expressly grant, and you represent and warrant that you have a right to grant, to Nutmeg, along with it assigns and licensees, a royalty-free, sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, reproduce, modify, publish, list information regarding, edit, translate, distribute, publicly perform, publicly display, and make derivative works of all such User Content and your name, voice, and/or likeness as contained in your User Content, if applicable, in whole or in part, and in any form, media or technology, whether now known or hereafter developed, for use in connection with the Service. You agree that this license includes the right for us to make your User Content available to other users of the Site." />
                        <Subtitle text="Monitoring of User Content; Account Termination" />
                        <Paragraph text="Nutmeg does not pre-screen User Content or Accounts. Nutmeg has the right, but not the obligation, to monitor use of the Site or to review or edit any User Content, but has the right to do so for the purpose of operating the Site; investigating, and enforcing possible violations of these Terms of Use; or to comply with applicable laws or the order or requirements of a court, administrative agency, or other governmental body." />
                        <Paragraph text="Nutmeg may remove any User Content at any time, with or without cause, with or without notice. Additionally, Company reserves the right to disable access to the Site, or terminate or suspend a Member’s Account, at any time, with or without cause, with or without notice. If Nutmeg terminates or suspends your Account for any reason you will not create another Account or otherwise attempt to access your Account without first receiving Nutmeg’s express written permission to do so." />
                        <Paragraph text="Any suspension or termination of your Account or other access to the Site, whether by Nutmeg or you, will not affect your obligations to Nutmeg under these Terms of Use (including, without limitation, proprietary rights and ownership, indemnification, and limitation of liability), which by their sense and context are intended to survive such suspension or termination." />
                        <Subtitle text="Third Parties" />
                        <Paragraph text="As part of the Site, Nutmeg may provide you with a convenient way to add and follow links to third party website(s) as well as post content or items belonging to or originating from third parties (Collectively known as, “Third Party Sites”)." />
                        <Paragraph text="Nutmeg is not responsible for any Third Party Sites and has no control over Third Party Sites or the promotions, materials, information, goods or services available on such sites. Third Party Sites are not investigated, monitored or checked for accuracy, appropriateness, or completeness by Nutmeg, and their inclusion on the Site does not imply approval or endorsement by Nutmeg. Links to Third Party Sites are provided “as is” and are subject to change or removal at any time." />
                        <Paragraph text="If you decide to leave the Site and access Third Party Sites you should review the applicable terms and policies, including privacy and data gathering practices, of any site to which you navigate from the Site or relating to any applications you use or install from the site." />
                        <Paragraph text="Nutmeg takes no responsibility and assumes no liability for any User Content or Third Party Sites that you or any other user or third party posts to or accessed from the Site. Under no circumstances will Nutmeg be responsible for any loss or damage resulting from anyone’s reliance on information or other content posted to or linked from the Site." />
                        <Subtitle text="Monetization of Web Links" />
                        <Paragraph text="Some links from Nutmeg to third party ecommerce sites are monetized by third party services. Nutmeg gets 'credit' for its part in helping match the buyer with the right item from the right seller for the gift recipient. This monetization is automatic and transparent and does not affect the item price. This disclosure is provided in accordance with the United States Federal Trade Commission’s 16 CFR § 255.5" />
                        <Subtitle text="Associate Disclosure" />
                        <Paragraph text="Nutmeg is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com, amazon.co.uk, amazon.ca, amazon.it, amazon.de, amazon.fr, amazon.co.au. “As an Amazon Associate we earn from qualifying purchases.”" />
                        <Paragraph text="CERTAIN CONTENT THAT APPEARS ON THIS SITE COMES FROM AMAZON SERVICES LLC. THIS CONTENT IS PROVIDED 'AS IS' AND IS SUBJECT TO CHANGE OR REMOVAL AT ANY TIME." />
                        <Subtitle text="Company’s Intellectual Property" />
                        <Paragraph text="The Site and its original content (excluding User Content), features and functionality are and will remain the exclusive property of Nutmeg Corporation and its licensors. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from Nutmeg." />
                        <Paragraph text="The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Nutmeg." />
                        <Subtitle text="User Consent to Receive Communication in Electronic Format" />
                        <Paragraph text="For contractual purposes, you (a) consent to receive communications from Nutmeg in an electronic form via the email address you submitted; and (b) agree that all Terms of Use, agreements, notices, disclosures, and other communications that Nutmeg provides to you electronically satisfy any legal requirement that such communications would satisfy if it were in writing. The foregoing does not affect your non-waivable rights." />
                        <Paragraph text="Nutmeg may also use your email address to send you transactional emails which are core to the service such as group invites, reminder emails initiated by others, and password reset emails. Nutmeg may also send other messages, including information about Nutmeg and special offers from the company, its affiliates and partners. You may opt out of these messages by changing your account settings for birthday reminders, using the unsubscribe options at the bottom of the email, or sending an email to support@nutmegwishlist.com or mail to the following postal address:" />
                        <Paragraph text="Opting out may prevent you from receiving messages regarding Nutmeg feature updates or special offers from Nutmeg affiliates and partners." />
                        <Subtitle text="WARRANTY DISCLAIMER" />
                        <Paragraph text="THE SITE, IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, Nutmeg EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, Nutmeg MAKES NO WARRANTY OR REPRESENTATION THAT ACCESS TO OR OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR FREE. YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USE OF FILES, INFORMATION, CONTENT OR OTHER MATERIAL OBTAINED FROM THE SITE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF WARRANTY, SO THIS PROVISION MAY NOT APPLY TO YOU." />
                        <Subtitle text="LIMITATION OF DAMAGES; RELEASE" />
                        <Paragraph text="TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Nutmeg, ITS AFFILIATES, DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS OR PARTNERS, BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, USE, OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, HOWEVER ARISING, THAT RESULT FROM (A) THE USE, DISCLOSURE, OR DISPLAY OF YOUR USER CONTENT; (B) YOUR USE OR INABILITY TO USE THE SERVICE; (C) THE SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SERVICE AVAILABLE; OR (D) ANY OTHER INTERACTIONS WITH Nutmeg OR ANY OTHER USER OF THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Nutmeg HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU." />
                        <Paragraph text="If you have a dispute with one or more users, or with a Third Party Site you accessed via our Site, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes." />
                        <Paragraph text="If you are a California resident, you waive California Civil Code §1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.”" />
                        <Subtitle text="Modification of Terms of Use" />
                        <Paragraph text="Nutmeg reserves the right to make changes to these Terms of Use (including the referenced privacy policy and DMCA notice) at any time by posting the revised Terms of Use to the Site. It is your responsibility to check the Site from time to time to view any such changes in the Agreement. Your continued use of the Site after the effective date of such changes constitutes your acceptance of such changes. Nutmeg may attempt to place a notice of significant changes to the terms by posting a notice on our homepage and/or sending an email to the email address you provided to us upon registration." />
                        <Paragraph text="All notices will be deemed made upon being transmitted or posted regardless of the date of receipt by you. If the modified Terms of Use are not acceptable to you, your only recourse is to cease using the Site." />
                        <Subtitle text="General Terms" />
                        <Paragraph text="These Terms of Use and your use of the Site are governed by the federal laws of the United States of America and the laws of the State of Texas, without regard to conflict of law provisions. Any failure on the part of Nutmeg to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. You agree that any cause of action related to or arising out of your relationship with Nutmeg must commence within ONE year after the cause of action accrues. Otherwise, such cause of action is permanently barred." />
                    </div>
            </div>
            </div>
        </div>
    )
}

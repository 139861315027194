import { FC } from 'react';

interface IProps {
    text: string,
}

export const Paragraph:FC<IProps> = (props:IProps) => {
    return(            
        <div className="text-lg max-w-prose mx-auto">
            <p className="mt-8 text-xl text-gray-500 leading-8">
                {props.text}
            </p>
        </div>
    )
}

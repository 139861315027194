import { AnyAction } from 'redux';

import { IWish } from '../_reducers/interfaces'

import { 
    RECEIVE_MY_WISH,
    RECEIVE_MY_WISHES,
    REQUEST_MY_WISH,
    REQUEST_MY_WISHES
} from './constants'

import { AxiosError } from 'axios';

import { client } from '../Client'

export const requestMyWishes = () => ({type:REQUEST_MY_WISHES})
export const receiveMyWishes = (wishes: IWish[]) => ({
    wishes,
    type:RECEIVE_MY_WISHES,
})

export const getMyWishes = (idToken:string) => {
    return((dispatch:any, getState:any):Promise<AnyAction>=>{
        dispatch(requestMyWishes());
        return new Promise((resolve,reject)=>{
            client.getMyWishes(idToken)
            .then((wishes:IWish[])=>{
                dispatch(receiveMyWishes(wishes))
            }).catch((err:AxiosError)=>{
                console.log(err)
            })
        })
    })
}

export const requestMyWish = (wishId: string) =>({
    wishId,
    type: REQUEST_MY_WISH,
})

export const receiveMyWish = (wish: IWish) => ({
    wish,
    type: RECEIVE_MY_WISH,
})

export const getMyWish = (idToken: string, wishId: string) => {
    return((dispatch:any, getState:any):Promise<AnyAction>=>{
        dispatch(requestMyWish(wishId))
        return new Promise((resolve, reject)=>{
            client.getWish(idToken, wishId)
            .then((wish:IWish)=>{
                dispatch(receiveMyWish(wish))
            }).catch((err:AxiosError)=>{
                console.log(err)
            })
        })
    })
}
import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { IInvite, IOrganization, IUser, IWish, IMembership, IJoinRequest } from '../_reducers/interfaces';

axios.interceptors.request.use(request => {
    console.log('Starting Request', request)
    return request
  })
  
axios.interceptors.response.use(response => {
console.log('Response:', response)
return response
})

export class Client {

    public appPath: string;

    private apiProtocol:string;
    private apiUrl:string;
    private basePath: string;
    private versionPath:string;

    constructor(apiProtocol:string = 'https://', apiUrl: string = 'nutmeg-prod.wwrld.vercel.app', versionPath:string = '') {

        this.apiProtocol = apiProtocol;
        if (process.env.REACT_APP_ENV === 'prod') {
            this.apiUrl = 'nutmeg-prod.wwrld.vercel.app'
            this.appPath = ''
        } else {
            this.apiUrl = apiUrl;
            this.appPath = ''
        }
        
        this.versionPath = versionPath;
        this.basePath = this.apiProtocol + this.apiUrl + this.versionPath;        
        console.log("using " + this.basePath)
    
    }
  
    public getOrganizations(idToken:string): Promise<IOrganization[]> {
        return new Promise ((resolve,reject)=>{
            const query = `query { organizations {name, id, isOrganizer,
                members {id, name, email}
                }}`
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config).then((response:AxiosResponse)=>{
                const organizations: IOrganization[] = response.data.data.organizations.map((org:IOrganization) => 
                    ({
                        id: org.id,
                        isOrganizer: org.isOrganizer,
                        name: org.name,
                        description: org.description,
                        members: org.members,
                        wishes: org.wishes,
                    }))
                resolve(organizations)
            }).catch((error)=>reject(error))
            

        })
    }

    public getOrgWishes(idToken:string, orgId:string): Promise<IWish[]>{
        return new Promise((resolve,reject)=>{
            const query = `
            query($orgId:ID){
                orgWishes(orgId:$orgId){
                  id,
                  name,
                  creator {id},
                  recipient { id, name, email },
                  purchased,
                  purchasor { id, name, email },
                  location,
                  url,
                  price,
                  comments,
                  year,
                  shares { id, organization {id, name}}
                }}
            `
            const variables = {orgId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config).then((response: AxiosResponse)=>{
                if(response.data.errors){reject(response.data.errors[0].message)}
                resolve(response.data.data.orgWishes)
            }).catch((err: AxiosError)=>reject(err))
        })

    }

    public getSharableOrgs(idToken:string, userId:string): Promise<IOrganization[]>{
        return new Promise((resolve,reject)=>{
            const query = `query($userId:ID) {sharableOrganizations(userId:$userId){id, name}}`
            const variables = {userId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config).then((response: AxiosResponse)=>{
                const sharableOrgs: IOrganization[] = response.data.data.sharableOrganizations.map((org:IOrganization)=>({
                    id: org.id,
                    name: org.name,
                }))
                resolve(sharableOrgs); 
            }).catch((err: AxiosError)=>reject(err))
        })
    }

    public getCurrentUser(idToken:string): Promise<IUser>{
        return new Promise((resolve, reject)=>{
            const query = `query {
                currentUser {
                    id,
                    name,
                    email,
                    minors { id, name},
                }}`
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query,},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config).then((response: AxiosResponse)=>{
                resolve(response.data.data.currentUser)
            }).catch((err: AxiosError)=>reject(err))
        })
    }

    public createWish(idToken:string, variables:any): Promise<IWish>{
        return new Promise((resolve,reject)=>{
            const query:string =`
            mutation createWish(
                $comments: String,
                $location: String,
                $name:String, 
                $price: Float,
                $recipient: ID, 
                $shares: [ID],
                $url: String,
                $year: Int
                ){
                createWish(input:{
                    comments: $comments,
                    location: $location,
                    name: $name,
                    price: $price,
                    recipient: $recipient,
                    shares: $shares,
                    url: $url,
                    year: $year
                }){wish{
                    id,
                    name,
                    shares{organization{id}},
                    recipient{id}
                }}
            }
            `
            const url:string = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                resolve(response.data.data.createWish.wish)
            }).catch(err=>reject(err))
        })
    }

    public createOrg(idToken:string, variables:any): Promise<IOrganization>{
        return new Promise((resolve,reject)=>{
            const query:string =`
            mutation createOrg(
                $name:String,
                $description:String 
                ){
                createOrganization(input:{
                    name: $name,
                    description: $description
                }){organization{id, name}}
            }
            `
            const url:string = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                resolve(response.data.data.createOrganization.organization)
            }).catch(err=>reject(err))
        })
    }

    public getMyWishes(idToken:string): Promise<IWish[]> {
        return new Promise ((resolve,reject)=>{
            const query = `query { myWishes {
                    id,
                    name,
                    location,
                    url,
                    price,
                    comments,
                    year,
                    }}`
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config).then((response:AxiosResponse)=>{
                resolve(response.data.data.myWishes)
            }).catch((error)=>reject(error))
            

        })
    }

    public markPurchased(idToken:string, wishId:string): Promise<IWish>{
        return new Promise((resolve,reject)=>{
            const query = `mutation markWishPurchased($wishId:ID){
                markWishPurchased(id:$wishId){wish{id, name}}
            }`
            const variables = {wishId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query,variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config).then((response:AxiosResponse)=>{
                resolve(response.data.data.markWishPurchased.wish)
            }).catch((error)=>reject(error))
        })
    }

    public unmarkPurchased(idToken:string, wishId:string): Promise<IWish>{
        return new Promise((resolve,reject)=>{
            const query = `mutation unmarkWishPurchased($wishId:ID){
                unmarkWishPurchased(id:$wishId){wish{id, name}}
            }`
            const variables = {wishId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query,variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config).then((response:AxiosResponse)=>{
                resolve(response.data.data.unmarkWishPurchased.wish)
            }).catch((error)=>reject(error))
        })
    }

    public createInvite(idToken:string, input:any): Promise<IInvite>{
        return new Promise((resolve, reject)=>{
            const query=`
            mutation createInvite($email: String, $orgId: ID){
                createInvite(input:{email:$email, orgId:$orgId}){
                  invite {
                    id,
                    email,
                    organization { id, name}
                  }}}
            `
            const variables = {...input}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            } 
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.createInvite.invite);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public destroyWish(idToken:string, wishId:any): Promise<string>{
        return new Promise((resolve, reject)=>{
            const query=`
            mutation destroyWish($wishId:ID){
                destroyWish(id:$wishId){message}
              }`
            const variables = {wishId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            } 
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.destroyWish.message);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public getMemberships(idToken:string, orgId:any): Promise<IMembership[]> {
        return new Promise((resolve, reject)=>{
            const query=`
            query($orgId:ID){memberships(orgId:$orgId){
                id,
                user{id, name, email, custodian{id, name, email}},
                organization {id, name},
                role
              }}
            `
            const variables = {orgId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.memberships);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public destroyMembership(idToken:string, membershipId:any): Promise<string>{
        return new Promise((resolve,reject)=>{
            const query=`
            mutation destroyMembership($membershipId:ID){
                destroyMembership(id:$membershipId){message}
              }`
            const variables = {membershipId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            } 
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.destroyMembership.message);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public getOrgInvites(idToken:string, orgId:any): Promise<IInvite[]> {
        return new Promise((resolve, reject)=>{
            const query=`
            query($orgId:ID){orgInvites(orgId:$orgId){
                id,email
              }}
            `
            const variables = {orgId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.orgInvites);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public destroyInvite(idToken:string, inviteId:any): Promise<string>{
        return new Promise((resolve,reject)=>{
            const query=`
            mutation destroyInviet($inviteId:ID){
                destroyInvite(id:$inviteId){message}
              }`
            const variables = {inviteId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            } 
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.destroyInvite.message);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public getMyInvites(idToken:string):Promise<IInvite[]>{
        return new Promise((resolve, reject)=>{
            const query=`
            query { myInvites {
                id,
                email,
                organization { id, name}
              }}
            `
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.myInvites);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public getWish(idToken: string, wishId:any): Promise<IWish>{
        return new Promise((resolve, reject)=>{
            const query=`
            query($wishId:ID) {
                wish(id:$wishId){
                    id,
                    name,
                    creator {id},
                    recipient { id, name, email },
                    purchased,
                    purchasor { id, name, email },
                    location,
                    url,
                    price,
                    comments,
                    year,
                    shares { id, organization {id, name}}
                }}
            `
            const variables = { wishId }
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.wish);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public modifyWish(idToken:string, variables:any): Promise<IWish>{
        return new Promise((resolve,reject)=>{
            const query:string =`
            mutation createWish(
                $comments: String,
                $location: String,
                $name:String, 
                $price: Float,
                $recipient: ID, 
                $shares: [ID],
                $url: String,
                $wishId: ID,
                $year: Int
                ){
                modifyWish(input:{
                    id: $wishId,
                    comments: $comments,
                    location: $location,
                    name: $name,
                    price: $price,
                    recipient: $recipient,
                    shares: $shares,
                    url: $url,
                    year: $year
                }){wish{id, name, shares{id, organization{id}},recipient{id}  }}
            }
            `
            const url:string = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                resolve(response.data.data.modifyWish.wish)
            }).catch(err=>reject(err))
        })
    }

    public createMembership(idToken:string, variables:any): Promise<IMembership>{
        return new Promise((resolve,reject)=>{
            const query:string =`
            mutation createMembership(
                $userId:ID,
                $orgId: ID,
                $role: MemberRole,
              ){
                createMembership(input:{
                  userId:$userId,
                  orgId: $orgId,
                  role: $role,
                }){membership{id, user{id, name}, organization{id, name}}}
              }
            `
            const url:string = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                resolve(response.data.data.createMembership.membership)
            }).catch(err=>reject(err))
        })
    }

    public createMinor(idToken:string, name:string): Promise<string>{
        return new Promise((resolve,reject)=>{
            const query:string = `
            mutation createMinor($name:String){
                createMinor(name:$name){
                    message
                }
            }`
            const variables = {name}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.createMinor.message)
            }).catch(err=>reject(err))        
        })
    }

    public destroyMinor(idToken:string, minorId:string): Promise<string>{
        return new Promise((resolve,reject)=>{
            const query:string = `
            mutation destroyMinor($minorId:ID){
                destroyMinor(userId:$minorId){
                    message
                }
            }`
            const variables = {minorId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.destroyMinor.message)
            }).catch(err=>reject(err))        
        })
    }

    public createJoinRequest(idToken:string, variables:{userId:string; orgId:string}): Promise<IJoinRequest>{
        return new Promise((resolve,reject)=>{
            const query:string = `
            mutation createJoinRequest( $userId: ID, $orgId: ID){
                createJoinRequest( input: { userId: $userId, orgId: $orgId}){
                    joinRequest{
                        user { id, name},
                        organization {id, name}
                    }
                }
            }`
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.createJoinRequest.joinRequest)
            }).catch(err=>reject(err))        
        })
    }

    public getOrgJoinRequests(idToken:string, orgId:string): Promise<IJoinRequest[]> {
        return new Promise((resolve, reject)=>{
            const query=`
            query($orgId:ID){orgJoinRequests(orgId:$orgId){
                id,
                organization { id, name},
                user {id, name, custodian{id, name}}
              }}
            `
            const variables = {orgId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data: {query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.orgJoinRequests);
            }).catch(err=>{
                console.log(err)
                reject({message:err[0]})})
        })
    }

    public destroyJoinRequest(idToken:string, joinRequestId:string ): Promise<string>{
        return new Promise((resolve,reject)=>{
            const query:string = `
            mutation destroyJoinRequest($joinRequestId:ID){
                destroyJoinRequest(id:$joinRequestId){
                    message
                }
            }`
            const variables = {joinRequestId}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.destroyJoinRequest.message)
            }).catch(err=>reject(err))        
        })
    }

    public editName(idToken:string, name:string): Promise<IUser>{
        return new Promise((resolve,reject)=>{
            const query:string = `
            mutation editName($name:String){
                editName(name:$name){
                    user {
                        id, name, email
                    }
                }
            }`
            const variables = {name}
            const url = this.basePath
            const config: AxiosRequestConfig = {
                data:{query, variables},
                headers: {
                    "Authorization":"bearer " + idToken,
                },
                method: "POST",
                url,
            }
            axios(config)
            .then((response:AxiosResponse)=>{
                if(response.data.errors){reject({message:response.data.errors[0].message})}
                resolve(response.data.data.editName.user)
            }).catch(err=>reject(err))        
        })
    }


} // end Client

    
import { FC } from 'react';

interface IProps {
    text: string,
}

export const Title:FC<IProps> = (props:IProps) => {
    return(            
        <h1>
            <span className="
                mt-2 block
                text-2xl text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {props.text}
            </span>
        </h1>
    )
}

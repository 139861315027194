import { FC } from 'react';
import { useAuth0} from '@auth0/auth0-react'
import { AppHeader, Nav, WishForm } from '../components'
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom'

interface IProps {
    dispatch: any;
    myWishes: any;
}

interface IParams {
    recipientId: string,
}

export const AddWishScene:FC<IProps> = (props: IProps) => {
    const { isAuthenticated } = useAuth0();
    const { recipientId } = useParams<IParams>()

    if(!isAuthenticated){
        return(<Redirect to="/"/>)
    }

    return(
        <>
        <Nav/>
        <div className="py-10 h-screen">
            <AppHeader>Add a Wish!</AppHeader>
            <div className="max-w-7xl mx-auto">
                <WishForm
                    recipientId={recipientId}
                />
            </div>
        </div>
        </>
    )
}
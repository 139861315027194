import {FC, useState} from "react";

import { useAuth0 } from "@auth0/auth0-react";
import cx from 'classnames'
import {Link } from 'react-router-dom'

export const ProfileDropdown: FC = () => {
    const { logout, user } = useAuth0();

    const [show, setShow] = useState(false);

    const classes = cx({
        "hidden": !show,
        "absolute": show,
    });

    return(
        // {/* <!-- Profile dropdown --> */}
        <div className="ml-3 relative z-50">
            <div>
                {
                    user && (
                        <button 
                            className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            onClick={()=>setShow(!show)}
                            id="user-menu" 
                            aria-haspopup="true">
                          <img 
                            className="h-8 w-8 rounded-full"
                            src={user.picture} alt={user.name}/>
                        </button>
                    )
                }
            </div>
            {/* <!--
                Profile dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-200"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            --> */}
            <div
                onMouseOut={()=>setShow(!show)}
                className={`
                    ${classes}
                    origin-top-right
                    right-0 mt-2 w-48 rounded-md 
                    shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5
                    `}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu">
                <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
                    >Your Profile</Link>
                {/* <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a> */}
                <button
                    
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={() => logout({ returnTo: window.location.origin })}
                    role="menuitem"
                    >Sign out</button>
            </div>
        </div>
    )
}



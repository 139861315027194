import {
    RECEIVE_MY_WISHES,
    REQUEST_MY_WISHES,
    REQUEST_MY_WISH,
    RECEIVE_MY_WISH,
} from '../_actions'

// define default state

import {IMyWishesReducer,} from './interfaces'

const defaultState: IMyWishesReducer = {
    isFetching: false,
    wishes: [],
    lastUpdated: 0
}

// reducer
export const myWishes = (
    state=defaultState,
    action:any,
): IMyWishesReducer => {
    switch (action.type) {
        case RECEIVE_MY_WISHES:
            return Object.assign({},state,{
                isFetching:false,
                wishes: action.wishes,
                lastUpdated: Date.now(),
            })
        
        case REQUEST_MY_WISHES:
            return Object.assign({},state,{
                ...state,
                isFetching:true,
            })
        
        case REQUEST_MY_WISH:
            let j = state.wishes.findIndex(wish=>wish.id===action.wishId)

            return Object.assign({}, state,{
                isFetching:false,
                wishes: [
                    ...state.wishes.slice(0,j),
                    {
                        ...state.wishes[j],
                        isFetching:true,
                    },
                    ...state.wishes.slice(j+1)
                ],
                lastUpdated: Date.now()
            })
        
        case RECEIVE_MY_WISH:
            let r = state.wishes.findIndex(wish=>wish.id===action.wish.id)

            return Object.assign({},state,{
                isFetching: false,
                wishes:[
                    ...state.wishes.slice(0,r),
                    {
                        ...action.wish,
                        isFetching:false,
                    },
                    ...state.wishes.slice(r+1)      
                ],
                lastUpdated: Date.now()
            })

        default:
            return state
    }
}
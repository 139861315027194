import { FC } from 'react';
import { useAuth0} from '@auth0/auth0-react'
import { useEffect } from 'react'

import { getOrganizations } from '../services/_actions'

import { useSelector } from 'react-redux'

import { AppContent, AppHeader, AppToolbar, Button, Nav } from '../components'
import { IOrgReducer, IUserReducer } from '../services/_reducers/interfaces';
import { useDispatch } from 'react-redux'
import { Link, Redirect, useHistory } from 'react-router-dom';





interface IProps {
    dispatch: any;
    myWishes: any;
}

interface IRootSelector {
    user: IUserReducer,
    organizations: IOrgReducer
}

export const SelectGroupScene:FC<IProps> = (props: IProps) => {
    const { user, organizations } = useSelector((state:IRootSelector) => state)
    const { isAuthenticated } = useAuth0();
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(()=>{
        if(isAuthenticated) {
            dispatch(getOrganizations(user.idToken!))
        }},[isAuthenticated, dispatch, user])

    if(!isAuthenticated){
        return(<Redirect to="/"/>)
    }

    return(
        <>
        <Nav/>
        <div className="py-10 h-screen">
        <AppHeader>My Groups</AppHeader>
        <AppToolbar>
            <Button onClick={()=>{history.push('/create-group')}}>Create Group</Button>
        </AppToolbar>
            <AppContent>
                <ul className="grid grid-cols-1 p-4 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {organizations.organizations.map(o=>
                    <li className="
                        col-span-1 flex shadow-sm rounded-md 
                        border
                        border-gray-200
                        hover:bg-green-800
                        ">
                        {/* <div className="flex-1 flex items-center border border-gray-200 bg-white rounded-md truncate">
                            <div className="flex-1 inline-flex items-center justify-center px-auto py-2 text-sm truncate"> */}
                            <Link 
                                to={"group/" + o.id}
                                className="
                                    col-span-1 
                                    text-center
                                    w-full
                                    p-10
                                    text-gray-900 font-medium 
                                    hover:text-gray-200
                                    shadow-sm rounded-md 
                                    "
                            >
                                <span>{o.name}</span>
                            </Link>
                        </li>
                    )}
                </ul>
            </AppContent>
        </div>
        </>
    )
}

import { FC, useCallback, useMemo } from 'react';
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { 
    AccordianSection, AppContent, AppHeader, AppToolbar, 
    Button, Nav } from '../../components'
import { IInvite, IJoinRequest, IOrgReducer, IUserReducer } from '../../services/_reducers/interfaces';
import { useParams } from 'react-router-dom';
import { MemberCard } from './MemberCard'
import { Client } from '../../services/Client'
import { IMembership } from '../../services/_reducers/interfaces'
import { AxiosError } from 'axios';


interface IRootSelector {
    user: IUserReducer,
    organizations: IOrgReducer
}

interface IParams {
    groupId: string,
}

export const ManageGroupScene:FC = () => {
    // services
    const { user, organizations } = useSelector((state:IRootSelector) => state)
    const client = useMemo(()=>new Client(),[])
    
    let { groupId } = useParams<IParams>()

    const [memberships, setMemberships] = useState<{
        loading:boolean, memberships: IMembership[]}>({loading: false, memberships:[]})

    const [invites, setInvites] = useState<{
        loading:boolean, invites: IInvite[]}>({loading: false, invites:[]})
    
    const [joinRequests, setJoinRequests] = useState<{
        loading:boolean, joinRequests: IJoinRequest[]}>({loading: false, joinRequests:[]})

    const group = organizations.organizations.find(org=>org.id === groupId)!

    
    const getMemberships = useCallback(()=>{
        client.getMemberships(user.idToken!, groupId)
        .then((memberships: IMembership[])=>{
            setMemberships({loading:false,memberships})
        }).catch(err=>{
            console.log(err)        
            setMemberships({loading:false, memberships:[]})
        })
    },[groupId, client, user])

    
    
    useEffect(()=>{
        const getJoinRequests = (groupId: string) => {
            client.getOrgJoinRequests(user.idToken!, groupId)
            .then((joinRequests: IJoinRequest[])=>{
                setJoinRequests({loading:false, joinRequests})
            }).catch((err:AxiosError)=>console.log(err))
        }
    
        const getInvites = (groupId:number) => {
            client.getOrgInvites(user.idToken!, groupId)
            .then((invites: IInvite[])=>{setInvites({loading:false, invites})})
            .catch(err=>console.log(err))}

        getMemberships()
        getInvites(parseInt(groupId))
        getJoinRequests(groupId)
    }, [getMemberships, groupId, client, user])


    return(
        <>
            <Nav/>
            <div className="py-10 h-screen">
                <AppHeader>Manage {group.name}</AppHeader>
                <AppToolbar>
                    <span>
                        <Button>Invite</Button>
                    </span>
                    <span/>
                </AppToolbar>
                <AppContent>
                    <AccordianSection text="Current Members">
                        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 p-4">
                            {
                            memberships
                            .memberships
                            .filter(m=>m.user.id !== user.id)
                            .sort((a,b)=>a.user.name > b.user.name ? 1 : -1).map(m=>
                                <MemberCard
                                    membership={m}
                                    getMemberships={getMemberships}
                                />
                            )}
                        </ul>
                    </AccordianSection>
                    <AccordianSection text="Pending Invites">
                        {JSON.stringify(invites)}
                    </AccordianSection>
                    <AccordianSection text="Pending Join Requests">
                        {JSON.stringify(joinRequests)}
                    </AccordianSection>
                </AppContent>
            </div>
        </>
    )
}

import { FC } from 'react';
import { useAuth0} from '@auth0/auth0-react'
import { Redirect} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { 
    AppContent, AppHeader, AppToolbar, 
    Button, Nav } from '../components'
import { IOrgReducer, IUserReducer } from '../services/_reducers/interfaces';
import { Link, useHistory, useParams } from 'react-router-dom';


interface IRootSelector {
    user: IUserReducer,
    organizations: IOrgReducer
}

interface IParams {
    groupId: string,
}

export const GroupScene:FC = () => {
    const { user, organizations } = useSelector((state:IRootSelector) => state)
    const { isAuthenticated } = useAuth0();
    const history = useHistory()
    let { groupId } = useParams<IParams>()

    const group = organizations.organizations.find(org=>org.id === groupId)!

    if(!isAuthenticated){
        return(<Redirect to="/"/>)
    }

    return(
        <>
            <Nav/>
            <div className="py-10 h-screen">
                <AppHeader>Select Member to View Wishlist</AppHeader>
                <AppToolbar>
                    <span>
                        {
                            group.isOrganizer?
                            <>
                            <Button
                                disabled={!group.isOrganizer}
                                title="Group organizers can invite new members."
                                onClick={()=>{history.push(`/invite/${group.id}`)}}
                                >Invite</Button>
                            <Button
                                disabled={!group.isOrganizer} 
                                title="Group organizers can manage members."
                                onClick={()=>history.push(`/group/${group.id}/manage`)}
                            >Manage</Button>
                            </>
                            :
                            null
                        }
                    </span>
                    <span/>
                </AppToolbar>
                <AppContent>
                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 p-4">
                        {group.members.filter(m=>m.id !== user.id).sort((a,b)=>a.name > b.name ? 1 : -1).map(u=>
                        <li className="
                            col-span-1
                            flex shadow-sm rounded-md 
                            border
                            border-gray-200
                            hover:bg-green-800
                            text-gray-900
                            hover:text-gray-200
                            ">
                                <Link 
                                    to={`/user/${groupId}/${u.id}`}
                                    className="text-left p-4 font-medium shadow-sm rounded-md w-full">
                                    <span>{u.name}</span>
                                </Link>
                            </li>
                        )}
                    </ul>
                </AppContent>
            </div>
        </>
    )
}


import { FC } from 'react';

import { Transition } from '@headlessui/react'

import { SelectOption } from './SelectOption'

import { useState } from 'react'

import { IOption } from './SelectOption'

interface IProps {
  options: IOption[],
  label: string,
  selected?: IOption[] | undefined,
  setSelected?: any,
}

export const SelectMulti:FC<IProps> = (props:IProps) => {

  const [open, setOpen] = useState(false)

  const [cursor, setCursor] = useState(0)

  const { label, options, selected, setSelected} = props

  const handleSelection = (option:IOption) => {
      const ids = selected?.map(o=>o.id)
      console.log(`clicked: ${JSON.stringify(option)}`)
      if (ids?.includes(option.id)) {
          const filtered = selected?.filter(o => (o.id !== option.id))
          setSelected(filtered)
        } else {
            const newSelected = selected?.map(s=>s)
            newSelected?.push(option)
            setSelected(newSelected)
        }
  }

  const handleKeyDown = (e:any) => {
    
    if (e.keyCode === 40) {
      setOpen(true)
    }

    if (e.keyCode === 38 && cursor > 0) {
      e.preventDefault()
      setCursor(cursor-1)
    } else if (e.keyCode === 38 && cursor === options.length - 1){
      e.preventDefault()
      setCursor(0)
    } else if (e.keyCode === 40 && cursor < options.length - 1) {
      e.preventDefault()
      setCursor(cursor+1)
    } else if (e.keyCode === 40 && cursor === 0) {
      e.preventDefault()
      setCursor(options.length - 1)
    }

    if (e.keyCode === 13) {
      e.preventDefault()
      handleSelection(options[cursor])
    }

    if (e.keyCode === 27) {
      e.preventDefault()
      setOpen(false)
      setCursor(0)
    }
  }

  const clickSelected = (option:IOption) => {
    handleSelection(option)
    setCursor(0)
  }

  const handleBlur = (event:any) => {
   
      // if the blur was because of outside focus
      // currentTarget is the parent element, relatedTarget is the clicked element
      if (!event.currentTarget.contains(event.relatedTarget)) {
          setOpen(false)
      }
  
  }

  return(
    <div className="sm:col-span-6">
      <label id="listbox-label" className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative" onKeyDown={handleKeyDown}>
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          className="
            relative w-full bg-white border border-gray-300
            rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default
            focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          onClick={()=>setOpen(!open)}
        //   onBlur={()=>setOpen(false)}
          >
          <span className="block truncate">
            {   
                selected?
                    selected.length === 0?
                    "Share with Groups"
                    : selected.map(s=>s.name).join(", ")
                :"Share with Groups"
            }
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            {/* <!-- Heroicon name: selector --> */}
            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </span>
        </button>
        <Transition
          show={open}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="absolute mt-1 w-full rounded-md bg-white shadow-lg">
            <ul 
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              onBlur={handleBlur}
              >
              {options.map((o, i)=>
                <SelectOption
                  key={o.id} 
                  option={o}
                  selected={selected?selected.map(m=>m.id).includes(o.id):false}
                  clickSelected={clickSelected}
                  highlight={cursor === i}
                  />)}
            </ul>
          </div>
        </Transition>
      </div>
    </div>

  )
}

import {FC, useState} from "react";

import { IWish, IUserReducer } from '../../../services/_reducers/interfaces' 
import { deleteWish, getMyWishes} from '../../../services/_actions'
import cx from 'classnames'
import { useDispatch, useSelector } from "react-redux";

import { client } from '../../../services/Client'
import { Spinner } from "../../Spinner";

interface IProps {
    wish: IWish,
    mine?: boolean,
}

interface IRootState {
    user: IUserReducer
}

export const DestroyWish: FC<IProps> = (props:IProps) => {
    //services
    const { user } = useSelector((state:IRootState)=>state)
    const dispatch = useDispatch()
    
    // initialize state
    const { mine, wish } = props
    const [destroying, setDestroying] = useState(false)
    
    const disableDestroy = mine?false:!(wish.creator.id === user.id)
    const deleteClasses = cx({
        'cursor-not-allowed': disableDestroy,
        'bg-gray-200': disableDestroy,
        'hover:bg-red-100': !disableDestroy,
    })

    const removeWish = () => {
        setDestroying(true)
        client.destroyWish(user.idToken!, wish.id)
        .then((message:string)=>{
            dispatch(deleteWish(wish.id))
            dispatch(getMyWishes(user.idToken!))
        })
        .catch((message:string)=>{
          console.log(message)
        })
    
      }


    return (
        <button 
            type="button"
            className={`
                relative inline-flex items-center 
                px-4 py-2 rounded-br-md
                text-sm font-medium text-gray-700
                focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
                ${deleteClasses}
                `}
            disabled={disableDestroy}
            title="Delete Wish"
            onClick={()=>removeWish()}
        >
            {
                destroying?
                <div className="w-full flex justify-center">
                    <Spinner/>
                </div>
                :
                <svg className="h-5 w-5 mx-auto fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
            }
            
        </button>
  )
};

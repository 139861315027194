import { FC } from 'react';
import { useAuth0} from '@auth0/auth0-react'
import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { AppHeader, Nav, WishForm } from '../components'
import { IWish, IMyWishesReducer, IUserReducer, IOrgWishesReducer } from '../services/_reducers/interfaces';
import { useParams } from 'react-router';


interface IProps {
    dispatch: any;
    myWishes: any;
}

interface IRootSelector {
    user: IUserReducer,
    myWishes: IMyWishesReducer,
    orgWishes: IOrgWishesReducer,
}

interface IParams {
    wishId: string,
}

export const EditWishScene:FC<IProps> = (props: IProps) => {
    const { myWishes, orgWishes } = useSelector((state:IRootSelector) => state)
    const [wish, setWish] = useState<IWish | undefined>(undefined)
    const { isAuthenticated } = useAuth0();
    const { wishId } = useParams<IParams>()

    useEffect(()=>{
        const wish = 
            wishId?
            myWishes.wishes.some(wish => wish.id === wishId)?
                myWishes.wishes.find(wish=> wish.id === wishId)
                :
                orgWishes.wishes.some(wish=> wish.wish.id === wishId)?
                orgWishes.wishes.find(wish => wish.wish.id === wishId)!.wish
                : undefined : undefined


        if(wish){setWish(wish)}

    },[myWishes, orgWishes, wishId])

    if(!isAuthenticated){
        return(<Redirect to="/"/>)
    }

    return(
        <>        
            <Nav/>
            <div className="py-10 h-screen">
                <AppHeader>Edit Wish!</AppHeader>
                <div className="max-w-7xl mx-auto">
                    <WishForm
                        wish={wish}
                    />
                </div>
            </div>
        </>

    )
}
import {FC} from "react";
import {useAuth0} from '@auth0/auth0-react'
import cx from 'classnames'

import { NavLink } from './NavLink'

interface IProps {
    show: boolean,
  }

export const MobileMenu: FC<IProps> = (props:IProps) => {
    
    const {logout, user, isAuthenticated } = useAuth0()

    const classes = cx({
        "hidden": !props.show,
        "md:hidden": true,
    })

    return(
        // {/* <!--
        //     Mobile menu, toggle classNamees based on menu state.
        
        //     Menu open: "block", Menu closed: "hidden"
        // --> */}
        <div className={`${classes}`}>
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLink to="/" mobile={true}>Home</NavLink>
              <NavLink to="/ideas" mobile={true}>Ideas</NavLink>
              {isAuthenticated && (
                <>
                  <NavLink to="/my-wishes" mobile={true}>My Wishes</NavLink>
                  <NavLink to="/groups" mobile={true}>Groups</NavLink>
                </>
              )}
            </div>
            { isAuthenticated && user && (
                <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5 sm:px-6">
                        <div className="flex-shrink-0">
                        <img 
                            className="h-10 w-10 rounded-full"
                            src={user.picture} 
                            alt={user.name}/>
                        </div>
                        <div className="ml-3">
                    <div className="text-base font-medium text-white">{user.name}</div>
                    <div className="text-sm font-medium text-gray-200">{user.email}</div>
                        </div>
                        <button className="ml-auto flex-shrink-0 p-1 rounded-full text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">View notifications</span>
                        {/* <!-- Heroicon name: bell --> */}
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                        </svg>
                        </button>
                    </div>
                    <div className="mt-3 px-2 sm:px-3">
                        <NavLink to="/profile" mobile={true}>Your Profile</NavLink>
                        {/* <NavLink to="#" mobile={true}> Settings</NavLink> */}
                        <button
                            onClick={() => logout({ returnTo: window.location.origin })}
                            className={`
                                text-gray-100
                                hover:bg-gray-100
                                hover:text-gray-900
                                px-3
                                py-2 
                                rounded-md
                                block
                                text-base
                                font-medium"
                            `}
                            >Sign Out</button>
                    
                    </div>
                </div>
            )}
        </div>
    )
}



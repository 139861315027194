
import { FC, ReactChild } from 'react';

import cx from 'classnames'

interface IProps {
    children: ReactChild;
    className?: string;
    disabled?:boolean;
    onClick?: ()=>void;
    title?:string;
}

export const Button:FC<IProps> = (props:IProps) => {
    
    const buttonClasses = cx({
        'cursor-not-allowed': props.disabled,
    })
    return(            

        <button
            type="button"
            disabled={props.disabled}
            title={props.title}
            className={`
                ${props.className}
                flex
                sm:inline-flex
                items-center
                px-4 py-2 my-2
                mx-2 w-40
                border border-gray-300 shadow-sm
                text-sm font-medium justify-center
                rounded-md text-gray-700 bg-white
                hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400
                ${buttonClasses}
                `}
            onClick={props.onClick}
                >
        {props.children}
        </button>
    )
}

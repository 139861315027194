import { FC, useEffect } from 'react';

import { useState } from 'react'

import { Button, NumberInput, TextArea, TextInput } from '../../components'

import { SelectSingle } from '../SelectSingle'

import { useDispatch, useSelector, } from 'react-redux'
import { IOrgReducer, IUser, IWish } from '../../services/_reducers/interfaces';
import { IOption } from '../SelectOption';
import { SelectShares } from './SelectShares'
import { getMyWishes, getOrgWishes } from '../../services/_actions'

import { client } from '../../services/Client'
import { useHistory } from 'react-router';

interface IProps {
    recipientId?: string,
    wish?: IWish
}

export const WishForm:FC<IProps> = (props:IProps) => {

    const history = useHistory()
    const dispatch = useDispatch()

    // redux
    const { organizations, user } = useSelector((state:{
        organizations:IOrgReducer
        user:IUser,
    })=>state)

    // get props
    const { recipientId, wish} = props

    // initialize state
    const [wishName, setWishName ] = useState<string>("")
    const [recipient, setRecipient] = useState<IOption>({id:"-1", name:"Select Recipient", unavailable:false})
    const [location, setLocation ] = useState<string>("")
    const [url, setUrl] = useState<string>("")
    const [price, setPrice] = useState<number>(0.00)
    const [comments, setComments] = useState<string>("")
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [validationMsg, setValidationMsg] = useState<string | undefined>("")
    const [shares, setShares] = useState<IOption[]>([])
    const [recipientOptions, setRecipientOptions] = useState<IOption[]>([])




    const pushNextLoc = (w:IWish) => {
        const mine = w.recipient.id === user.id
        if(mine){
            dispatch(getMyWishes(user.idToken))
            history.push('/my-wishes')
        } else {
            let oid:string = w.shares![0].organization.id
            dispatch(getOrgWishes(user.idToken, oid))
            history.push(`/user/${oid}/${w.recipient.id}`)
        }
    }

    const handleSave = () => {
        const payload = {
            comments,
            location,
            name: wishName,
            price,
            recipient: recipient.id,
            shares: shares.map(s=>s.id),
            url,
            wishId: "-1",
            year: new Date().getFullYear(),
        }

        if(!wish){
            client.createWish(user.idToken, payload)
            .then(w=>{
                console.log(w)
                pushNextLoc(w)
            })
        }
        if(wish){
            payload.wishId = wish.id 
            client.modifyWish(user.idToken, payload)
            .then(w=>{
                console.log(w)
                history.push('/my-wishes')
                pushNextLoc(w)
            })
        }
        console.log(JSON.stringify(payload))
    }


    useEffect(()=>{
        // combine the member lists of all the user organizations
        let allRecipients: IUser[] = [];
        let i: number;

        for (i=0; i < organizations.organizations.length; i++){
            allRecipients = allRecipients.concat(organizations.organizations[i].members)
        }
        allRecipients.push(user)
            
        // make the list of eligible recipients unique
        const uniqueRecipientIds = Array.from(new Set(allRecipients.map(u=>u.id)))
        const recipients: IUser[] = uniqueRecipientIds.map(id=>allRecipients.find(u=>u.id===id)!)
        const recipientOptions: IOption[] = recipients.map(
            r=>({
                id: r.id,
                name: r.name,
                unavailable: false,
            } as IOption)!
            ).sort((a,b)=> a.name > b.name?1:-1)
        
            // handle recipient in props
        const recipientLookup: IOption | undefined = 
        recipientId ?
        recipientOptions.map(r=>r.id).some(r=>r === recipientId) ?
        recipientOptions.find(r=>r.id === recipientId) : undefined : undefined
        
        if(recipientLookup){
            setRecipient(recipientLookup)
            setRecipientOptions(recipientOptions)
        }
    }, [recipientId, organizations, user])
    
    useEffect(()=>{    
        setWishName(wish?.name || "")
        if(wish?.recipient){
            setRecipient({id:wish?.recipient?.id, name:wish?.recipient?.name, unavailable:false} || {id:"-1", name:"Select Recipient", unavailable:false})
        }
        setLocation(wish?.location || "")
        setUrl(wish?.url || "")
        setPrice(wish?.price || 0.00)
        setComments(wish?.comments || "")
        if(wish?.shares){
            setShares(wish.shares.map(s=>({id:s.organization.id, name:s.organization.name, unavailable:false})))
        }
    },[wish])
    
    useEffect(()=>{   
        // form validations
        if(recipient.id !== user.id && shares.length === 0){
            setSaveDisabled(true)
            setValidationMsg("Wish must be shared with at least one group.")
            return
        }
        if(wishName === "") {
            setSaveDisabled(true)
            setValidationMsg("Wish Name is required.")
            return;

        }
        if(recipient.id === "-1"){
            setSaveDisabled(true)
            setValidationMsg("Wish Name is required.")
            return
        }
        setSaveDisabled(false)
        setValidationMsg("")
    },[shares, wishName, recipient, user])

    return(            
    <>
        <div className="space-y-8 divide-y divide-gray-200 p-8">
            <div className="space-y-8 divide-y divide-gray-200">
                <div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">              
                        <TextInput
                            label="Wish Name"
                            name="wish_name"
                            id="wish_name"
                            value={wishName}
                            setValue={setWishName}
                            // setValue={(value:string)=>{localDispatch(getAction('UPDATE_WISHNAME', value))}}
                            />
                        <SelectSingle
                            options={recipientOptions}
                            label="Select Recipient"
                            selected={recipient}
                            setSelected={setRecipient}
                            cleanUp={()=>{setShares([])}}
                            // setSelected={(value:IOption)=>{localDispatch(getAction('UPDATE_RECIPIENT', value))}}
                            />
                        <TextInput
                            label="Where to Buy"
                            name="location"
                            id="location"
                            value={location}
                            setValue={setLocation}
                            // setValue={(value:string)=>{localDispatch(getAction('UPDATE_LOCATION', value))}}
                            />
                        <TextInput
                            label="URL"
                            name="url"
                            id="url"
                            value={url}
                            setValue={setUrl}
                            // setValue={(value:string)=>{localDispatch(getAction('UPDATE_URL', value))}}
                            />
                        <NumberInput
                            label="Price"
                            name="price"
                            id="price"
                            value={price}
                            setValue={setPrice}
                            // setValue={(value:number)=>{localDispatch(getAction('UPDATE_PRICE', value))}}
                            />
                        <TextArea
                            label="Comments"
                            name="comments"
                            id="comments"
                            value={comments}
                            setValue={setComments}
                            // setValue={(value:string)=>{localDispatch(getAction('UPDATE_COMMENTS', value))}}
                            />
                        <SelectShares
                            shares={shares}
                            setShares={setShares}
                            // setShares={(value:IOption[])=>{localDispatch(getAction('UPDATE_SHARES', value))}}
                            recipientId={recipient.id}
                            />
                    </div>
    
                    <div className="pt-5">
                    <div className="flex justify-end">
                        <p className="mt-1 max-w-2xl text-sm text-red-500 p-4">
                        {validationMsg}
                        </p>
                    </div>

                    <div className="flex justify-end">
                        <Button
                            onClick={handleSave}
                            disabled={saveDisabled}
                        >
                            Save
                        </Button>
                    </div>
                    </div>
                    <span className="pt-10">{' '}</span>
                </div>
            </div>
        </div>
    </>
    )
}

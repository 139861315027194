
import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { client } from '../../services/Client';
import { IOrganization, IUser } from '../../services/_reducers/interfaces';
import { IOption } from '../SelectOption';
import { SelectMulti } from '../SelectMulti'


interface IProps {
    recipientId: string,
    shares: IOption[],
    setShares: any,
}

export const SelectShares:FC<IProps> = (props:IProps) => {
    const { user } = useSelector((state:{user:IUser})=>state)
    const { recipientId, shares, setShares } = props
    const [options, setOptions] = useState<IOrganization[]>([])

    useEffect(()=>{
        client.getSharableOrgs(user.idToken, recipientId)
        .then((sharableOrgs:IOrganization[])=>{
            setOptions(sharableOrgs)
        }).catch((err)=>{
            console.log(err)
        })
    },[user, recipientId, setOptions])

    return(            
        <SelectMulti
            options={options}
            label="Select Shares"
            selected={shares}
            setSelected={setShares}/>
    )
}

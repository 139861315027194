

import {FC} from "react";

interface IProps {
    title: string,
    suggested?: boolean,
    year?: number,
}

export const WishTitle: FC<IProps> = (props:IProps) => {
  return (

    <div className="flex justify-between items-start space-x-3">
        <h3 className="text-gray-900 text-sm font-medium break-normal">{props.title}</h3>
        {
            props.suggested?
            null
            :
            null
        }
        {
          props.year?
          <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{props.year}</span>
          :
          null
        }
    </div>
  )
};

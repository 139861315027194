import { FC } from 'react';
// import { useAuth0 } from "@auth0/auth0-react";

export const LearnMoreSection:FC = () => {
    
    // const { user } = useAuth0();

    return(
        <div className="py-12 bg-gray-50" id="LearnMore">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                a better way to give gifts
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Nutmeg is a platform for sharing gift wishlists
            </p>
            </div>

            <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div className="flex">
                <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-800 text-white">
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                    </div>
                </div>
                <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                    Create groups with your family and friends
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                    You can share each item on your wishlist with one or many groups.
                    </dd>
                </div>
                </div>

                <div className="flex">
                <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-800 text-white">
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                </div>
                <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                    Browse Gift History
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                    Trying to remember if you bought something last year?  Nutmeg keeps track of wishlists through time so you go back and see what you wished for in previous years, and what was bought for people in your group too.
                    </dd>
                </div>
                </div>

                <div className="flex">
                <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-800 text-white">
                    {/* <!-- Heroicon name: lightning-bolt --> */}
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    </div>
                </div>
                <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                    Mark items purchased
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                    No more duplicate gifts!  Whenever you mark a gift for someone in your group purchased, it is marked anonymously purchased across all groups.
                    </dd>
                </div>
                </div>

                <div className="flex">
                <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-800 text-white">
                    {/* <!-- Heroicon name: annotation --> */}
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                    </svg>
                    </div>
                </div>
                <div className="ml-4">
                    <dt className="text-lg leading-6 font-medium text-gray-900">
                    To-Do List
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                    Keep track of who you need to buy gifts for, and who you've already covered.
                    </dd>
                </div>
                </div>
            </dl>
            </div>
        </div>
        </div>

    )
}

import {FC, useState} from "react";
import { IUserReducer, IWish } from "../../../services/_reducers/interfaces";
import { useSelector, useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { getWish } from '../../../services/_actions'
import { client } from '../../../services/Client'
import cx from 'classnames' 
import { Spinner } from "../../Spinner";

interface IProps {
    wish: IWish,
    mine?: boolean,
}

interface IRootState {
    user: IUserReducer
}

export const MarkPurchased: FC<IProps> = (props:IProps) => {
    const { mine, wish} = props
    const user = useSelector((state:IRootState) => state.user)
    const dispatch = useDispatch()
    const [purchasing, setPurchasing] = useState(false)
    
    const otherPurchasor = (wish.purchased && wish.purchasor?.id !== user.id)
    const disablePurchase:boolean = mine ? true : otherPurchasor ? true : false 
    const meForOther = !mine && wish.purchased && wish.purchasor?.id === user.id    



    // style
    const purchaseClasses = cx({
        'cursor-not-allowed': disablePurchase,
        'bg-gray-200': disablePurchase,
        'hover:bg-gray-50': !disablePurchase,
        'bg-green-600': wish.purchased
    })

    // onClicks
    const markPurchased = () => {
        setPurchasing(true)
        client.markPurchased(user.idToken!, wish.id)
        .then((wish:IWish)=>{
            dispatch(getWish(user.idToken!, wish.id))
            setPurchasing(false)
        })
    }

    const unmarkPurchased = () => {
        setPurchasing(true)
        client.unmarkPurchased(user.idToken!, wish.id)
        .then((wish:IWish)=>{
            dispatch(getWish(user.idToken!, wish.id))
            setPurchasing(false)
        }).catch((err: AxiosError)=>{
            console.log(err)
        })
    }

    return (
        <button 
          type="button"
          key={`purchase_${wish.id}`}
          className={`
          relative
          inline-flex 
          text-center
          py-2
          rounded-bl-md bg-white text-sm font-medium text-gray-700
          focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
          ${purchaseClasses}
          `}
          disabled={disablePurchase}
          title={
              !wish.purchased && !mine ? 
              "Mark Purchased" :
              wish.purchased && !meForOther ? "Already Purchased" : "Unmark Purchased"
          }          
          onClick={wish.purchased?()=>unmarkPurchased():()=>markPurchased()}
          >

            {
                purchasing?
                <span className="w-full flex justify-center">
                    <Spinner/>
                </span>
                :
                <svg className={`h-5 w-5 mx-auto ${purchasing?"animate-ping":null}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                {
                !wish.purchased || !meForOther?
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
                    :
        
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
                }
                </svg>
            }

      </button>
  )
};

import {
    CLEAR_DEFAULT_SELECTED,
    SET_DEFAULT_SELECTED,
} from '../_actions'

// define default state


// reducer
export const defaultSelected = (
    state={defaultSelectedOrg:undefined, defaultSelectedUser:undefined},
    action:any,
) => {
    switch (action.type) {
        case SET_DEFAULT_SELECTED:
            const {defaultSelectedOrg, defaultSelectedUser} = action
            return Object.assign({},state,{
                defaultSelectedOrg,
                defaultSelectedUser,
            })
        
        case CLEAR_DEFAULT_SELECTED:
            return Object.assign({},state,{
                defaultSelectedOrg:undefined,
                defaultSelectedUser:undefined,
            })
        
        default:
            return state
    }
}
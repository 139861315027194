
import { FC } from 'react';

interface IProps {
  label: string,
  value?: string | undefined,
  setValue?: any,
  name?:string,
  id?:string,
}

export const TextInput:FC<IProps> = (props:IProps) => {
    
  const handleChange = (event:any) => {props.setValue(event.target.value)}

  return(            
        <div className="sm:col-span-6">
        <label htmlFor="street_address" className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
        <div className="mt-1">
          <input
            type="text"
            name={props.name} 
            id={props.id}
            autoComplete="street-address"
            value={props.value}
            onChange={handleChange}
            className="
                shadow-sm
                focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
        </div>
      </div>
    )
}

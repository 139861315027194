import {FC} from "react";

import { WishButtons } from './WishButtons'
import { WishLink } from './WishLink'
import { WishTitle } from './WishTitle'
import { IWish } from "../../services/_reducers/interfaces";

interface IProps {
    wish: IWish,
    mine?:boolean,
}

export const WishCard: FC<IProps> = (props:IProps) => {
  const { mine, wish } = props

  return (
    <li key={wish.id} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
    <div className="w-full flex items-center justify-between p-6 space-x-6">
      <div className="flex-1 h-28 overflow-y-scroll">
        <WishTitle title={wish.name} year={wish.year}/>
        
        {
          wish.creator? 
            wish.creator?.id !== wish.recipient?.id ?
              <>
                <div className="my-2">
                  <span 
                        title="Suggested by a different user than recipient."
                        className="flex-shrink-0 px-2 py-0.5 border border-yellow-800 text-yellow-800 text-xs font-medium bg-white rounded-full">
                      suggested
                  </span>
                </div>
                <hr/>
              </>
            : null : null
        }
        <div className="inline-flex align-center justify-between w-full t-1 text-gray-800 text-sm overflow-wrap break-normal">
          <WishLink url={wish.url} location={wish.location}/>
          <p className="mt-1">${wish.price?wish.price:"_"}</p>
        </div> 
        <hr className="mt-1"/>
        <div className="overflow-y-scroll">
          <p className="mt-1 text-gray-800 text-sm break-normal">{wish.comments}</p>
        </div>

      </div>
    </div>
    
      <WishButtons wish={wish} mine={mine}/>    
  </li>
  )
};


import { FC } from 'react';

interface IProps {
    children: any,
}

export const AppToolbar:FC<IProps> = (props:IProps) => {
    return(            
    <div className="block sm:flex justify-between px-6 max-w-7xl mx-auto sm:px-6 lg:px-8 pt-8">
        {props.children}
    </div>
    )
}

import { useAuth0 } from '@auth0/auth0-react';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppContent, AppHeader, Nav } from '../components'
import { IUser } from '../services/_reducers/interfaces';
import { Redirect } from 'react-router-dom'

interface IState {
    user: IUser
}

export const ProfileScene:FC = () => {
    const { user } = useSelector((state:IState)=>state)
    const auth = useAuth0()

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    
    if(!auth.isAuthenticated){
        return(<Redirect to="/"/>)
    }

    return(
        <div>
            <Nav/>
            <AppHeader>Profile</AppHeader>
            <AppContent>
                <div className="p-8 space-y-3">
                    <img 
                        src={auth.user.picture} 
                        alt="profile" 
                        style={{height:"140px", borderRadius:"50%"}}
                        />
                    <p><strong>Name:</strong> {user.name}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                </div>

            </AppContent>
        </div>
    )
}
